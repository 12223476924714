import React, {useState, useEffect} from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import { makeStyles } from '@mui/styles';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';

const useStyles = makeStyles({
  root: {},
})

export default function CV() {
  const classes = useStyles();
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }
  return (
    <Box className={classes.root}>
     <Container >
     

     <Document
        file={'/Resume.pdf'}
        onLoadSuccess={onDocumentLoadSuccess}
      >
     <p>Page {1} of {numPages}</p>

        <Page pageNumber={1} />
     <p>Page {2} of {numPages}</p>

        <Page pageNumber={2} />
      </Document>

      
    </Container>
    </Box>
  );
}
