import axios from "axios";
async function getCoordintes() {
  try{
    const { data } = await axios.get("https://geolocation-db.com/json/");
    return data;

  }catch(ex){
    console.log("Error getting lon lan: ", ex);
    return null;
 
  }
}
async function getAddressInfoApi(lat, lon) {
  try {
    const key2 = "pk.4529b762e5f7f6e3bae3b9ea9b614afd";
    const url2 = `https://us1.locationiq.com/v1/reverse.php?key=${key2}&lat=${lat}&lon=${lon}&format=json`;
    const { data } = await axios.get(url2);
    return data;
  } catch (ex) {
    console.log("Error getting city name: ", ex);
    return null;
  }
}
async function getAddressInfo() {
  const { latitude, longitude } = await getCoordintes();
  
   let addressesStr = localStorage.getItem("addresses");
  if(addressesStr){
    let addresses = JSON.parse(addressesStr);
    let data = addresses.find(ele => ele.latitude == latitude && ele.longitude == longitude);
    if(data){
      return data;
    }else{
      data = await getAddressInfoApi(latitude,longitude);
      addresses.push({latitude, longitude,data });
      localStorage.setItem("addresses", JSON.stringify(addresses));
      return data;
    }
  }else{
    let ar = [];
    const data = await getAddressInfoApi(latitude,longitude);
    ar.push({latitude, longitude,data });
    localStorage.setItem("addresses", JSON.stringify(ar));
    return data;
  }
}
async function getWeather(latitude, longitude) {
  try {
    const key = "b24eb9c80e8543f4e3de5cbc6cc80125";

    const url = `https://api.openweathermap.org/data/2.5/onecall?lat=${latitude}&lon=${longitude}&units=metric&appid=${key}`;
    const data = await axios.get(url);
    return data;
  } catch (ex) {
    console.log("Error getting weather: ", ex);
    return null;
  }
}
//SAR ==> USD
async function getEuroExchange() {
    try {
        const history = localStorage.getItem("xchSarToUsd");
        if(history) {
            return JSON.parse(history);
        }else{
          const key = "f05e9d09310352152cfcd527f57ea6a4";
          const url = `http://api.exchangeratesapi.io/v1/latest?access_key=${key}`;
          
          const {data} = await axios.get(url);
          console.log("data: " ,data);
          localStorage.setItem("exchangeSymbols", JSON.stringify(data));
          return data;
        }
    } catch (ex) {
      console.log("Error getting weather: ", ex);
      return null;
    }
  }
  
async function getSymbolsExchange() {
  try {
      const history = localStorage.getItem("EuroExchange");
      if(history) {
          return JSON.parse(history);
      }else{
        const key = "f05e9d09310352152cfcd527f57ea6a4";
        const url = `http://api.exchangeratesapi.io/v1/symbols?access_key=${key}`;
        const {data} = await axios.get(url);
        console.log("data: " ,data);
        localStorage.setItem("EuroExchange", JSON.stringify(data));
        return data;
      }
  } catch (ex) {
    console.log("Error getting weather: ", ex);
    return null;
  }
}

export default {
  getCoordintes,
  getAddressInfo,
  getWeather,
//   getSymbolsExchange,
//   getEuroExchange
};
