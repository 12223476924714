import React, { useState, useEffect } from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Container from "@mui/material/Container";
import { makeStyles } from "@mui/styles";
import { useTheme } from "@emotion/react";
import $ from "jquery";
import { useHistory } from "react-router-dom";
import { Slide } from "@mui/material";

import PartyEgg from "./Eggs/PartyEgg";
import FlipCoin from "./Eggs/FlipCoin";
import { useMode } from "../../contexts/ModeProvider";

const useStyles = makeStyles({
  root: {
    background: ({ colors }) => colors.bg1.card,
    padding: " 55px 16px ",
    background: "linear-gradient(to right, #4facfe 0%, #00f2fe 100%);",
  },
  egg: {
    margin: "10px !important",
    width: 200,
    height: 200,
    borderRadius: 20,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: ({mode})  => mode == "dark" ? "#001e3c !important" :  "white !important",
  },
  eggEnd: {
    position: "absolute",
    zIndex: 99999999999,
    width: "100%",
    height: "100%",
    background: "white",
    display: "none",
  },
  container: {
    position: "relative",
    zIndex: 9,
  },
});

export default function Eggs() {
  const { colors, isMobile,mode } = useMode();
  const theme = useTheme();
  const history = useHistory();
  const classes = useStyles({ colors,mode });
  const [egg, setEgg] = React.useState();
  const [loaded, setLoaded] = React.useState(false);

  const [partySound, setPartySound] = React.useState(null);

  const skew = () => {
    let i = 0;
    let finish = 200;
    const skIntrvl = setInterval(() => {
      if (i == finish) clearInterval(skIntrvl);
      $(".rootAll").css("transform", `skew(-3deg,${i}deg`);
      i++;
    }, 100);
  };
  const endEgg = () => {
    partySound.pause();
    $(`.eggPic`).css("background", "#3399ff");
    setEgg(null);
  };
  React.useEffect(() => {
    var sound = document.createElement("audio");
    sound.setAttribute("preload", "auto");
    sound.setAttribute("controls", "none");
    sound.loop = false;
    sound.setAttribute("id", "partySound");
    sound.style.display = "none";

    sound.source = document.createElement("source");
    sound.source.src = "eggs/party.mp3";
    sound.source.setAttribute("type", "audio/mp3");

    sound.append(sound.source);
    document.body.append(sound);
    setPartySound(sound);
    setLoaded(true);
  }, []);
  const doEgg = (theEgg) => {
    setEgg(theEgg);
    $(`.eggPic.${theEgg}`).css("background", "url('eggs/brokenEgg.jpg')");
    if (theEgg == "party") {
      partySound.play();
      window.setTimeout(() => {
        endEgg();
        $("#dancing").remove();
      }, 30000);
    } else if (theEgg == "coin") {
      partySound.play();
      window.setTimeout(() => {
        endEgg();
      }, 8000);
    } else if (theEgg == "skew") {
      partySound.play();
      skew();
      window.setTimeout(() => {
        endEgg();
      }, 26000);
    } else if (theEgg == "rubiks") {
      history.push("/rubiks");
    } else if (theEgg == "alpaca") {
      history.push("/alpacagenerator");
    } else if (theEgg == "exchangeRate") {
      history.push("/exchangeRate");
    }else if(theEgg == "gpt_detector"){
      const win = window.open("https://gpt-2-output-detector.vercel.app/", "_blank");
      win.focus();
    }
  };
  const items = [
    {
      id: 1,
      delay: ".5",
      name: "rubiks",
      cls: "rubiks",
      title: "Rubik's cube timer",
      description: "Timer and scrambler for rubik's lovers",
    },
    {
      id: 2,
      delay: "1s",
      name: "alpaca",
      cls: "alpaca",
      title: "Alpaca generator",
      description: "Generate your own aplaca customized by your options",
    },
    {
      id: 3,
      delay: "1.5s",
      name: "exchangeRate",
      cls: "exchangeRate",
      title: "Rate exchange",
      description: "Convert EUR to any kind of currency",
    },
    {
      id: 4,
      delay: "2s",
      name: "gpt_detector",
      cls: "test",
      title: "GPT-2 Output Detector",
      description: "Examines text and tells you how likely it was written by a human or an AI",
    },
    
    {
      id: 5,
      delay: "2.5s",
      name: "party",
      cls: "party",
      title: "Make a party",
      description:
        " It will make a party on your mobile screen. JUST FOR FUN !",
    },
    {
      id: 6,
      delay: "3s",
      name: "skew",
      cls: "skew",
      title: "Skew it all !",
      description: " It will skew your mobile screen. JUST FOR FUN !",
    },
  ];

  const OneEgg = ({ delay, cls, name, title, description }) => (
    <Slide
      direction="right"
      in={loaded}
      style={{ transitionDelay: delay }}
      mountOnEnter
      unmountOnExit
    >
      <Button
        variant="contained"
        className={classes.egg + " " + cls}
        fullWidth={isMobile}
        onClick={() => doEgg(name)}
      >
        <Typography fontWeight={800} variant="h5" color="text.primary">
          {title}
        </Typography>
        <Typography
          sx={{
            fontSize: "11px",
            marginTop: "10px",
          }}
          fontWeight={500}
          variant="body1"
          color="text.secondary"
          gutterBottom
        >
          {description}{" "}
        </Typography>
      </Button>
    </Slide>
  );
  return (
    <Box className={classes.root}>
      <Container className={classes.container}>
        <Typography
          variant="h2"
          align="left"
          sx={{
            mt: 1,
            color: "white",
          }}
        >
          Wizard
          <span style={{ color: "white" }}> TOOLS</span>{" "}
        </Typography>
        <Typography
          variant="subtitle1"
          color="text.secondary"
          sx={{
            mb: 5,
            mt: 1,
          }}
        >
          Mini tools and ideas for fun
        </Typography>
        {loaded ? (
          <Box
            sx={
              isMobile ? { flexDirection: "column", alignItems: "center" } : {}
            }
            display="flex"
            flexWrap={"wrap"}
          >
            {items.map((ele) => (
              <OneEgg {...ele} key={ele.id} />
            ))}
          </Box>
        ) : (
          <CircularProgress />
        )}
      </Container>
      {egg == "party" ? (
        <>
          <PartyEgg />
        </>
      ) : egg == "coin" ? (
        <FlipCoin />
      ) : (
        ""
      )}
      <Box className={classes.eggEnd}></Box>
    </Box>
  );
}
