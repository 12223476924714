import React, { useState, useEffect } from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import { makeStyles } from "@mui/styles";
import { useMode } from "../contexts/ModeProvider";
import $ from "jquery";

const useStyles = makeStyles({
  root: {
    minHeight: "90vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    overflow: "hidden",
    background: ({ colors }) => colors.bg1.bg,
    "& canvas": {
      background: "white",
      margin: 10,
    },
  },
});

var myGamePiece;
var myObstacles = [];
var myScore;
var mySound;
var gamingSound;

var myGameArea = {
  canvas: document.createElement("canvas"),
  canvasWidth: 200,
  canvasHeight: 270,
  start: function (width, height) {
    $(".canvasContainer canvas").remove();
    if (width < 480) {
      this.canvasWidth = 390;
    } else {
      this.canvasWidth = 720;
    }
    this.canvas.width = this.canvasWidth;
    this.canvas.height = 270;
    this.canvasHeight = this.canvas.height;

    this.context = this.canvas.getContext("2d");
    $(".canvasContainer").append(this.canvas);
    this.frameNo = 0;
    this.interval = setInterval(updateGameArea, 20);
    gamingSound.play();
  },
  clear: function () {
    this.context.clearRect(0, 0, this.canvas.width, this.canvas.height);
  },
  stop: function () {
    clearInterval(this.interval);
  },
  continue: function () {
    this.interval = setInterval(updateGameArea, 20);
  },
};

function component(width, height, color, x, y, type) {
  this.type = type;
  if (type == "image") {
    this.image = new Image();
    this.image.src = color;
  }
  this.width = width;
  this.height = height;
  this.speedX = 0;
  this.speedY = 0;
  this.x = x;
  this.y = y;
  this.update = function () {
    let ctx = myGameArea.context;
    if (this.type == "text") {
      ctx.font = this.width + " " + this.height;
      ctx.fillStyle = color;
      ctx.fillText(this.text, this.x, this.y);
    } else if (this.type == "image") {
      ctx.drawImage(this.image, this.x, this.y, this.width, this.height);
      ctx.fillStyle = "blue";
    } else {
      ctx.fillStyle = color;
      ctx.fillRect(this.x, this.y, this.width, this.height);
    }
  };
  this.newPos = function () {
    if (
      this.x + this.speedX != myGameArea.canvasWidth &&
      this.x + this.speedX > 0
    ) {
      this.x += this.speedX;
    }

    if (
      this.y + this.speedY != myGameArea.canvasHeight &&
      this.y + this.speedY > 0
    ) {
      this.y += this.speedY;
    } else if (
      this.y + this.speedY == myGameArea.canvasHeight &&
      this == myGamePiece
    ) {
      this.y = 0;
    } else if (this.y + this.speedY <= 0 && this == myGamePiece) {
      this.y = myGameArea.canvasHeight;
    }
  };
  this.crashWith = function (otherobj) {
    var myleft = this.x;
    var myright = this.x + this.width;
    var mytop = this.y;
    var mybottom = this.y + this.height;
    var otherleft = otherobj.x;
    var otherright = otherobj.x + otherobj.width;
    var othertop = otherobj.y;
    var otherbottom = otherobj.y + otherobj.height;
    var crash = true;
    if (
      mybottom < othertop ||
      mytop > otherbottom ||
      myright < otherleft ||
      myleft > otherright
    ) {
      crash = false;
    }
    return crash;
  };
}
function sound(src, loop) {
  this.sound = document.createElement("audio");
  this.sound.setAttribute("preload", "auto");
  this.sound.setAttribute("controls", "none");
  this.sound.loop = loop;
  this.sound.setAttribute("id", "stopSound");
  this.sound.style.display = "none";

  this.source = document.createElement("source");
  this.source.src = src;
  this.source.setAttribute("type", "audio/wav");
  this.sound.append(this.source);

  $(".canvasContainer").append(this.sound);
  this.play = function () {
    this.sound.play();
  };
  this.stop = function () {
    this.sound.pause();
  };
}
function updateGameArea() {
  var x, height, gap, minHeight, maxHeight, minGap, maxGap, i;
  for (i = 0; i < myObstacles.length; i += 1) {
    if (myGamePiece.crashWith(myObstacles[i])) {
      gamingSound.stop();
      mySound.play();
      myGameArea.stop();
      return;
    }
  }
  myGameArea.clear();
  myGameArea.frameNo += 1;
  if (myGameArea.frameNo == 1 || everyinterval(150)) {
    x = myGameArea.canvas.width;
    minHeight = 20;
    maxHeight = 200;
    height = Math.floor(
      Math.random() * (maxHeight - minHeight + 1) + minHeight
    );
    minGap = 50;
    maxGap = 200;
    gap = Math.floor(Math.random() * (maxGap - minGap + 1) + minGap);
    myObstacles.push(new component(10, height, "#3399ff", x, 0));
    myObstacles.push(
      new component(10, x - height - gap, "#3399ff", x, height + gap)
    );
  }
  for (i = 0; i < myObstacles.length; i += 1) {
    myObstacles[i].speedX = -1;
    myObstacles[i].newPos();
    myObstacles[i].update();
  }
  myScore.text = "SCORE: " + myGameArea.frameNo;
  myScore.update();
  myGamePiece.newPos();
  myGamePiece.update();
}

function everyinterval(n) {
  if ((myGameArea.frameNo / n) % 1 == 0) {
    return true;
  }
  return false;
}

function moveup() {
  myGamePiece.speedY = -1;
}

function movedown() {
  myGamePiece.speedY = 1;
}

function moveleft() {
  myGamePiece.speedX = -1;
}

function moveright() {
  myGamePiece.speedX = 1;
}

function clearmove() {
  myGamePiece.speedX = 0;
  myGamePiece.speedY = 0;
}

function startGame(colors, width, height) {
  console.log("started: ");
  myGamePiece = new component(30, 30, "/game/wizard.svg", 10, 120, "image");
  myScore = new component(
    "1rem",
    "Consolas",
    "black",
    myGameArea.canvasWidth == 390 ? 240 : 720,
    40,
    "text"
  );
  mySound = new sound("/game/stop.wav");
  gamingSound = new sound("/game/gaming.wav", true);
  myGameArea.start(width, height);
}
function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}
let playing = true;
const stop = () => {
  myGameArea.stop();
  playing = false;
  gamingSound.stop();
};
const resume = () => {
  myGameArea.continue();
  playing = true;
  gamingSound.play();
};

export default function Game() {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  const { colors } = useMode();
  const classes = useStyles({ colors });
  
  useEffect(() => {
    startGame(colors, windowDimensions.width, windowDimensions.height);
  }, []);

  return (
    <Box className={classes.root}>
      <div className="canvasContainer"></div>
      <Box
        sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      >
        <Button
          variant="contained"
          color="primary"
          onMouseDown={moveleft}
          onTouchStart={moveleft}
          onTouchEnd={clearmove}
          onMouseUp={clearmove}
        >
          Left{" "}
        </Button>
        <Button
          variant="contained"
          color="primary"
          onMouseDown={moveright}
          onTouchStart={moveright}
          onTouchEnd={clearmove}
          onMouseUp={clearmove}
        >
          Right{" "}
        </Button>
        <Button
          variant="contained"
          color="primary"
          onMouseDown={moveup}
          onTouchStart={moveup}
          onTouchEnd={clearmove}
          onMouseUp={clearmove}
        >
          Up
        </Button>
        <Button
          variant="contained"
          color="primary"
          onMouseDown={movedown}
          onTouchStart={movedown}
          onTouchEnd={clearmove}
          onMouseUp={clearmove}
        >
          {" "}
          Down{" "}
        </Button>
      </Box>
      <Box sx={{ display: "flex" }}>
        {playing ? (
          <Button variant="contained" color="primary" onClick={stop}>
            Stop
          </Button>
        ) : (
          <Button variant="contained" color="primary" onClick={resume}>
            Continue{" "}
          </Button>
        )}
      </Box>
    </Box>
  );
}
