import React, { useState, useEffect } from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { makeStyles } from "@mui/styles";
import $ from "jquery";
import { useMediaQuery } from "@mui/material";
import { useTheme } from "@emotion/react";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";


import { useMode } from "../../contexts/ModeProvider";
import {  startSaderRain, nextBall } from "../../lib/SaderRain";
// import MyPts from "../MyPts";

const useStyles = makeStyles({
  root: {
    position: "relative",
    flexDirection: "column",
    paddingTop: 20,
    paddingBottom: 20,
    minHeight: "50vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: ({ colors }) => colors.bg1.bg,

    "& .canvasContainer": {
      //   bottom: 0,
      //   position: "absolute",
      backgroundColor: "transparent",
      border: "none",
      display: "flex",
      justifyContent: "center",
    },
    "& #pt":{
      width: "100%",
      height: "100%",
      position: "absolute",
      zIndex: 1,
    }
  },
  game: {
    zIndex: 999,
    width: "100%",
    height: "100%",
  },
});


export default function Pad() {
  const [enough, setEnough] = React.useState(false);
  const [started, setStarted] = React.useState(false);

  const { colors, mode } = useMode();
  const classes = useStyles({ colors });
  const theme = useTheme();
  const isMobile = !useMediaQuery(theme.breakpoints.up("md"));

  const hitme = () => {
    if (!started) {
      // const startSound = new sound("/game/drums.wav");
      // startSound.play();
      setStarted(true);
    }

    setEnough(nextBall());
  };
  useEffect(() => {
    startSaderRain($, "#3399ff", isMobile, ".canvasContainer");
  }, [isMobile]);
  return (
    <Box className={classes.root}>
      <Box className={classes.game}>
        {enough ? (
          <Typography variant="h4" color="text.primary" gutterBottom>
            You hit enough, thanks for loving
          </Typography>
        ) : (
          ""
        )}
        <div className="canvasContainer"></div>

        {enough ? (
          ""
        ) : (
          <Button
            variant="contained"
            color="secondary"
            onClick={hitme}
            disabled={enough}
            sx={
              started
                ? {
                    mb: 2,
                  }
                : {
                    mb: 2,
                    animation: "myBounce",
                    animationDuration: " 2s",
                    animationIterationCount: "infinite",
                  }
            }
            startIcon={<PlayArrowIcon />}
            fullWidth
          >
            {started ? "HIT MORE ! " : "HIT ME TO PLAY !"}
          </Button>
        )}
      </Box>
      <div id="pt"></div>
    </Box>
  );
}
