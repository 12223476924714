import React, { useContext,  } from "react";
import { blue,  orange } from "@mui/material/colors";
import { createTheme } from "@mui/material/styles";
import { useMediaQuery } from "@mui/material";

const ModeContext = React.createContext();

export function useMode() {
  return useContext(ModeContext);
}

const getDesignTokens = (mode, colors) => ({
  typography: {
    fontFamily: "IBM Plex Sans",
    h2:{
      fontSize: "clamp(1.5rem, 0.9643rem + 1.4286vw, 2.25rem)",
      lineHeight: "1.22222",
      fontWeight: "700",
    },
    subtitle1:{
      fontSize: "1rem",
      fontWeight: "400",
      lineHeight: "1.22222",
    },
    body1:{
      fontFamily:"Lato",
      
    }

  },
  palette: {
    mode,
    primary: colors.primary,
    secondary: colors.secondary,
    background: {
      paper: colors.bg2.card,
      default: colors.bg2.bg,
    },
    text: {
      primary: colors.textMain,
      secondary: colors.textSecondary,
    },
  },
});

export function ModeProvider({ children }) {
  const [mode, setMode] = React.useState("light");

  const colors =
    mode == "light"
      ? {
          primary: blue,
          secondary: orange,

          btn: "#007FFF",
          btnHover: "#0059B2",

          textMain: "#132F4C",
          textPrimary: "#007fff",
          textSecondary: "#46505A",
          bg1: {
            bg: "#F3F6F9",
            card: "#f6f9fe",
            card2:
              "linear-gradient(to right bottom, rgb(0, 127, 255), rgb(0, 89, 178) 120%)",
            cardBorder: "#E5E8EC",
          },
          bg2: {
            bg: "#fff",
            card: "#ffffffc7",
            cardBorder: "#E5E8EC",
            cardHover: "#fff",
          },
          bgSpecial:{
            bg: "#132F4C", 
          }
        }
      : {
          primary: { main: "#3399ff", light: "#8ac5ff", dark: "#0080ff" },
          secondary: { main: "#03dac6", light: "#65e9dd", dark: "#00a394" },
          btn: "#007fff",
          btnHover: "#0059b2",

          textMain: "#fff",
          textPrimary: "#3399ff",
          textSecondary: "#aab4be",

          bg1: {
            bg: "#0a1828",
            card: "#001e3c",
            card2:
              "#132f4c",
            cardBorder: "#5090d3",
          },
          bg2: {
            bg: "#001e3c",
            card: "#132f4c",
            cardBorder: "#265d97",
            cardHover: "#0A1929",
          },
          bgSpecial:{
            bg: "#132F4C", 
          }
        };

  const theme = createTheme(getDesignTokens(mode, colors));
  const toggleThemeMode = (now) => {
    const newmode = mode == "light" ? "dark" : "light";
    setMode(newmode);
  };
  const isMobile = !useMediaQuery(theme.breakpoints.up("md"));

  const value = {
    mode,
    theme,
    toggleThemeMode,
    colors,
    isMobile,
  };
  return <ModeContext.Provider value={value}>{children}</ModeContext.Provider>;
}
