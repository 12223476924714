import React, { useState, useEffect } from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import { makeStyles } from "@mui/styles";
import { useMode } from "../contexts/ModeProvider";
import $ from "jquery";
import getRandomScramble from "./scrambles";
import { setSeconds } from "date-fns";

const useStyles = makeStyles({
  root: {
    marginTop: ({ isMobile }) => (isMobile ? "10vh" : "5vh"),
    textAlign: "center",
  },
  card: {
    border: ({ colors }) => `1px solid ${colors.bg2.cardBorder}`,
    background: ({ colors }) => colors.bg2.cardHover,
    transition: "all .4s",
    transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    width: "90%",
    overflow: "hidden",
    flexWrap: "wrap",
    position: "relative",
    padding: 20,
    height: 50,
    borderRadius: 10,
    flexDirection: ({ isMobile }) => (isMobile ? "column" : "row"),
    "&:hover, &:focus": {
      background: ({ colors }) => colors.bg2.card,

      transform: ({ isMobile }) => (isMobile ? "" : "scale(1.1)"),
      boxShadow: "rgb(90 105 120 / 20%)  10px 1px 23px 0px",
    },
  },
  letter: {
    color: ({ colors }) => colors.textMain,
    marginBottom: 5,
    marginRight: 10,
    position: "absolute",
    left: "100%",
  },
});

export default function Rubiks() {
  const { isMobile, colors } = useMode();
  const [scrmbl, setScrmbl] = React.useState();

  const [playTimer, setPlayTimer] = useState(false);
  //   const [firstTime, setFirstTime] = useState(false);
  const [finishedShowing, setFinishedShowing] = useState(false);
  const [letters, setLetters] = useState([]);

  const [second, setSeconds] = useState(0);
  const [milleSec, setMilleSec] = useState(0);
  const [time, setTime] = useState("0.00");
  const classes = useStyles({ isMobile, colors, playTimer });
  const fasterShow = () => {
    setFinishedShowing(true);
  };
  const nextScramble = () => {
    const ele = getRandomScramble();
    setScrmbl(ele);
    $(letters[idx]).css("left", `100%`);

    let i = 0;
    let h = 10;
    let idx = 0;
    let num = 0;
    let finish = letters.length;
    const animateLetter = () => {
        console.log("finished showing: " , finishedShowing)
      if (idx == finish ) {
        setFinishedShowing(false);
        return;
      }
      console.log("animate");
      if (num > 90) {
        i = 0;
        h += 50;
      }
      num = 2 + i * 10;
      $(letters[idx]).css("top", `${h}%`);
      $(letters[idx]).animate({ left: `${num}%` }, 1000, () => {
        i++;
        idx++;
        animateLetter();
      });
    };
    animateLetter();
  };
  React.useEffect(() => {
    console.log("hiiiiiii", letters.length);
    if (letters.length > 0) {
      nextScramble();
    }
  }, [letters]);
  React.useEffect(() => {
    if (playTimer) {
      if (milleSec >= 100) {
        setSeconds((sec) => sec + 1);
        setMilleSec(0);
      }
      setTime(`${second}.${milleSec.toString().substr(0, 1)}`);
    }
  }, [milleSec, second]);
  React.useEffect(() => {
    let interval = null;

    if (playTimer) {
      interval = setInterval(() => {
        setMilleSec((ms) => ms + 1);
      }, 10);
    } else {
      clearInterval(interval);
    }
    return () => {
      clearInterval(interval);
    };
  }, [playTimer]);

  const startTimer = () => {
    // setFirstTime(true);
    setPlayTimer(true);
  };
  const stopTimer = () => {
    // setFirstTime(false);
    setPlayTimer(false);
    setMilleSec(0);
    setSeconds(0);
    setTime(`${second}.${milleSec}`);
  };
  useEffect(() => {
    const lets = document.getElementsByClassName("letter");
    setLetters(lets);
  }, [scrmbl]);
  useEffect(() => {
    const ele = getRandomScramble();
    setScrmbl(ele);

    window.addEventListener("keypress", function (e) {
      console.log("e: ", e.key);
      console.log("space: ", e.key === " ");
      if (e.key === "Spacebar" || e.key === " ") {
        e.preventDefault();
        console.log("playTimer: ", playTimer);
        if (playTimer) stopTimer();
        else startTimer();
      }
    });
  }, []);

  return (
    <Box className={classes.root}>
      <Container>
        <Typography variant="h4" color={colors.textPrimary} gutterBottom>
          Rubik's cube scrambler and timer{" "}
        </Typography>
        {scrmbl ? (
          <>
            <Typography variant="h5" color="text.primary" gutterBottom>
              Scramble:
            </Typography>
            <Box className={classes.card}>
              {scrmbl.ar.map((ele, idx) => (
                <span key={idx} className={classes.letter + " letter"}>
                  {ele}{" "}
                </span>
              ))}
            </Box>

            {finishedShowing ? (
              <Button
                style={{ marginTop: 8 }}
                color="secondary"
                variant="contained"
                onClick={nextScramble}
              >
                Other
              </Button>
            ) : (
              ""
            )}
          </>
        ) : (
          ""
        )}
        <Box mt={2}>
          <Typography variant="h5" color="text.primary" gutterBottom>
            Timer:
          </Typography>

          <Typography variant={playTimer ? "h1" : "h4"} color="text.primary">
            {time}
          </Typography>
          {playTimer ? (
            <Button color="secondary" variant="contained" onClick={stopTimer}>
              Stop Timer{" "}
            </Button>
          ) : (
            <Button color="secondary" variant="contained" onClick={startTimer}>
              Start Timer{" "}
            </Button>
          )}
        </Box>
      </Container>
    </Box>
  );
}
