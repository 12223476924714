import * as React from "react";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";

import ArticleIcon from "@mui/icons-material/Article";
import HomeIcon from "@mui/icons-material/Home";
import {
  Typography,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Box,
  AppBar,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import MenuIcon from "@mui/icons-material/Menu";
import Slide from "@mui/material/Slide";
import useScrollTrigger from "@mui/material/useScrollTrigger";

import { useHistory, useLocation } from "react-router";
import { blue } from "@mui/material/colors";
import SocialStack from "./SocialStack";

import { useMode } from "../contexts/ModeProvider";
import { DarkMode, LightMode } from "@mui/icons-material";

function HideOnScroll(props) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
  });

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}

const useStyles = makeStyles((theme) => ({
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  drawer: {
    flexShrink: 0,
    width: 200,

  },
  drawerPaper: {
    width: 200,
    backgroundColor: ({ colors }) => colors.bg2.bg + " !important",
    padding: "30px",

  },
  desktopNav: {
    "& ul": {
      padding: 0,
      margin: 0,
      listStyle: "none",
      display: "flex",
    },
    "& li": {
      color: theme.palette.text.secondary,
      ...theme.typography.body2,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      fontWeight: 700,
      padding: 4,
      margin: 2,
      cursor: "pointer",
      "& > a, & > div": {
        display: "inline-block",
        color: "inherit",
        textDecoration: "none",
        padding: theme.spacing(1),
        borderRadius: theme.shape.borderRadius,
        paddingBottom: 0,
        flexShrink: 0,
        fontWeight: 700,
      },
      "&:hover, &:focus": {
        // backgroundColor: ,
        color: theme.palette.primary.main,
      },
      "& > div": {
        cursor: "default",
      },
    },
  },
}));

function Header(props) {
  const history = useHistory();
  const location = useLocation();
  const { mode, theme, toggleThemeMode, colors } = useMode();
  const classes = useStyles({ colors });
  const isMdUp = useMediaQuery(theme.breakpoints.up("md"));
  const iconColor = mode == "dark" ? "white" : colors.textPrimary;
  const [open, setOpen] = React.useState(false);

  const sections = [
    { title: "Home", icon: <HomeIcon sx={{ color: iconColor }} />, path: "/" },
    {
      title: "Articles",
      icon: <ArticleIcon sx={{ color: iconColor }} />,
      path: "#articles",
    },
    // {
    //   title: "Projects",
    //   icon: <LanguageIcon sx={{ color: iconColor }} />,
    //   path: "/projects",
    // },
    // {
    //   title: "Message me",
    //   icon: <MessageIcon sx={{ color: iconColor }} />,
    //   path: "/message",
    // },
  ];

  const toggleDrawer = (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setOpen(!open);
  };

  const goTo = (path) => {
    history.push(path);
    setOpen(false);
  };
  const DarkLightIconBtn = () => (
    <IconButton
              sx={{ ml: 1 }}
              onClick={toggleThemeMode}
            >
              {theme.palette.mode === "dark" ? (
                <LightMode
                  className="boxAnimation_dark"
                />
              ) : (
                <DarkMode
                  className="boxAnimation_light"
                />
              )}
            </IconButton>
  )

  const DesktopNav = (props) => (
    <HideOnScroll {...props}>
      <AppBar
        component="nav"
        variant="dense"
        sx={{
          overflowX: "auto",
          backgroundColor: mode == "dark" ? "#0a1929" : "#ffffffb8",
          boxShadow:
            mode == "dark"
              ? " rgb(19 47 76) 0px -1px 1px inset"
              : "inset 0px -1px 1px #eaeef3",
        }}
      >
        <Toolbar
          sx={{
            justifyContent: "space-between",
            
          }}
        >
          <Box
            sx={{ display: "flex", alignItems: "center" }}
            className={classes.desktopNav}
          >
            <ul>
              {sections.map((section, idx) => (
                <li
                  key={idx}
                  onClick={() => goTo(section.path)}
                  className={classes.NavItem}
                >
                  {section.icon}
                  <Typography
                    sx={
                      location.pathname == section.path
                        ? { color: theme.palette.primary.main + " !important" }
                        : {}
                    }
                    component="a"
                    align="left"
                    gutterBottom
                  >
                    {section.title}{" "}
                  </Typography>
                </li>
              ))}
            </ul>
          </Box>
            <SocialStack iconColor={iconColor} />
          <Box sx={{ display: "flex", alignItems: "center" }}>

            <DarkLightIconBtn />
          </Box>
        </Toolbar>
      </AppBar>
    </HideOnScroll>
  );

  const MobileNav = (props) => (
    <HideOnScroll {...props}>
      <AppBar
        sx={{
          borderBottom: 1,
        }}
      >
        <Toolbar
          sx={{
            display: "flex",
            justifyContent: "space-between",
            backgroundColor:
              mode == "dark" ? "#0a1929 !important" : "#ffffffb8",
            boxShadow:
              mode == "dark"
                ? " rgb(19 47 76) 0px -1px 1px inset"
                : "inset 0px -1px 1px #eaeef3",
          }}
        >
          <IconButton onClick={toggleDrawer}> <MenuIcon /></IconButton>
          <Typography color="text.primary" onClick={() => goTo("/")} variant="h5">
            Antoin Sader
          </Typography>
          <Box>
          <DarkLightIconBtn />

          </Box>
        </Toolbar>
        <Drawer
          className={classes.drawer}
          variant={isMdUp ? "permanent" : "temporary"}
          classes={{
            paper: classes.drawerPaper,
          }}
          anchor="left"
          open={open}
          onClose={toggleDrawer}
        >
          <div className={classes.toolbar} />
          <Typography variant="h5"  color="text.primary" gutterBottom> Menu </Typography>
          <List>
            {sections.map((section, idx) => (
              <ListItem key={idx} button onClick={() => goTo(section.path)}>
                {section.icon}
                <ListItemText
                  sx={
                    location.pathname == section.path
                      ? {
                          color: blue,
                          ml: 1,
                        }
                      : { color: "black", ml: 1 }
                  }
                  primary={section.title}
                />
              </ListItem>
            ))}
          </List>
        </Drawer>
      </AppBar>
    </HideOnScroll>
  );
  return (
    <React.Fragment>{isMdUp ? <DesktopNav /> : <MobileNav />}</React.Fragment>
  );
}

export default Header;
