import React, { useState, useEffect } from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";

import { makeStyles } from "@mui/styles";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import { useMode } from "../contexts/ModeProvider";

import { useTheme } from "@emotion/react";
import { CircularProgress, Grid } from "@mui/material";
import cities from "cities.json";
import capitals from "../Data/country-by-capital-city.json";

import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

import apis, { getWeather } from "../Helpers/Apis";

const useStyles = makeStyles({
  flxClmn: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    minHeight: "80vh",
  },
  weatherNow: {
    position: "relative",
    minHeight: "40vh",
    zIndex: 1,
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundColor: ({ colors }) => colors.bg1.bg,
  },
  Box1: {
    backgroundColor: ({ colors }) => colors.bg1.bg,
  },
  Box2: {
    paddingTop: 30,
    backgroundColor: ({ colors }) => colors.bg2.bg,
  },
  weatherCard: {
    backgroundColor: ({ colors , mode}) => mode =="light" ? "white": colors.bg1.card2,
    border: "1px solid",
    borderColor: ({ colors }) => colors.bg1.cardBorder,
    display: "flex",
    flexDirection: ({ isMobile }) => (isMobile ? "column !important" : "row"),
    justifyContent: "center",
    alignItems: "center",

    borderRadius: 10,
    padding: 20,
    flexBasis: ({ isMobile }) => (isMobile ? "95%" : "35% !important"),
    marginBottom: "17px !important",
    display: "flex",
    transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    "&:hover": {
      boxShadow: "#5a6978d1 1px 1px 20px 0px",
    },
  },
  carousel: {
    "& .control-dots": {
      display: ({ isMobile }) => (isMobile ? "none" : "block"),
      bottom: "-10px",
    },
  },
});

export default function Weather() {
  const { isMobile, colors,mode } = useMode();
  const theme = useTheme();
  const classes = useStyles({ mode, colors, theme, isMobile });

  const [data, setData] = React.useState();
  const [address, setAddress] = React.useState();
  const [myCities, setCities] = React.useState();
  const [value, setValue] = React.useState("");

  const [changingAddress, setChangingAddress] = React.useState(false);
  
  //func for getting data from history or from API,
  //get cities and restructure them in a way to use
  useEffect(() => {
    const func = async () => {
        let HistoryStr = localStorage.getItem("lastWeather");
        let daily, myAddress, temp, weathers = [];
        const today = new Date();
        myAddress = await apis.getAddressInfo();

        if(HistoryStr){
          const HistoryObj = JSON.parse(HistoryStr)
          //there is weather array but don't know if there is specific city

          const historyWeathers = HistoryObj.weathers;
          const saveDate =  HistoryObj.timeStamp;
          const differenceDays = parseInt(
            (new Date() - new Date(saveDate)) / (1000 * 60 * 60 * 24)
          );
          console.log("myAddress: " , myAddress);
            
          if(differenceDays == 0 && historyWeathers.find(ele => ele.address.lon == myAddress.data.lon && ele.address.lat == myAddress.data.lat)){
            //dif = 0 and there is our address before
            console.log("there is history and on the same date and addreess");
            const theObj = historyWeathers.find(ele => ele.address.lon == myAddress.data.lon && ele.address.lat == myAddress.data.lat);
            temp = theObj.temp;
            weathers = historyWeathers;
          }else{
            //dif != 0 or there is not  our address before

            daily = await apis.getWeather(myAddress.lat, myAddress.lon);
            temp = construct(daily);
            weathers = historyWeathers.push({address: myAddress, temp,});
          }
        }else{
          //Browser history is empty
          //get current address and get weather of this address 
          
          daily = await apis.getWeather(myAddress.lat, myAddress.lon);
          temp = construct(daily);
          weathers.push({address: myAddress, temp,});
          //saved address is lon lat
          
          console.log("All got from the apis");
        }
        //save new history and set states 
        const objToSave = {timeStamp: today.toString(), weathers}
        localStorage.setItem("lastWeather", JSON.stringify(objToSave));
        
        setAddress(myAddress.data);
        setData(temp);       
    };
    func();
    let cts = [];
    cities.forEach((ele, idx) => {
      if (capitals.find((cp) => cp.city == ele.name) || ele.country == "SY") {
        cts.push({
          id: idx,
          label: `${ele.name}-${ele.country}`,
          lat: ele.lat,
          lng: ele.lng,
        });
      }
    });

    setCities(cts);
  }, []);
  const Cards = data
    ? [
        {
          id: 1,
          cls: "fa-clock",
          title: "Request Time",
          subTitle: `${data.requestTime}`,
        },
        {
          id: 2,
          cls: "fa-temperature-high",
          title: "Temperature",
          subTitle: `${data.temperature}`,
        },
        {
          id: 3,
          img: `${data.icon}`,

          title: "Weather",
          subTitle: `${data.weather} ${data.weatherDescription}`,
        },
        {
          id: 4,
          cls: "fa-wind",
          title: "Wind: ",
          subTitle: `${data.wind_deg} degrees /  ${data.wind_speed}`,
        },
        {
          id: 5,
          img: `/weather/sunrise.png`,
          title: "Sunrise: ",
          subTitle: `${data.sunrise}`,
        },
        {
          id: 6,
          img: `/weather/sunset.png`,

          title: "Sunset: ",
          subTitle: `${data.sunset}`,
        },
      ]
    : [];

  return data && address ? (
    <Box sx={{ py: isMobile ? 10: 5 }} className={classes.weatherNow}>
      <Box className={classes.Box1}>
        <Container>
          <Typography
            variant="h2"
            color="primary"
            align="left"
            gutterBottom
            sx={{
              fontSize: "0.875rem",
              lineHeight: "1.5",
              fontWeight: "700",
            }}
          >
            Weather
          </Typography>
          <Typography
            variant="h2"
            color="text.primary"
            align="left"
            sx={{
              mt: 1,
            }}
          >
            The weather in{" "}
            <span style={{ color: theme.palette.primary.main }}>
              {address.display_name}
            </span>
          </Typography>
          {/* <Typography
            color="text.secondary"
            variant="subtitle1"
            sx={{
              mb: 1,
              mt: 1,
            }}
          >
            Sometimes the location is wrong based on your IP. Fortunately, you
            can
            <Button
              variant="text"
              sx={{ padding: 0, marginLeft: "5px" }}
              color="secondary"
              onClick={() => {
                setChangingAddress(true);
              }}
            >
              change the location
            </Button>

          </Typography>
           */}
          {changingAddress && myCities ? (
            
            <Box display="flex" justifyContent={isMobile ? "center" : "flex-start"} alignItems="center">
              <Typography sx={{marginRight: 1}} variant="body1" color="text.primary" gutterBottom> City: </Typography>
            <Autocomplete
              value={value}
              
              onChange={(event, newValue) => {
                setValue(newValue);
              }}
              disablePortal
              options={myCities}
              renderOption={(props, option) => {
                return (
                  <li {...props} key={option.id}>
                    {option.label}
                  </li>
                );
              }}
              sx={{ width: 300 }}
              renderInput={(params) => {
                return <TextField {...params} />;
              }}
            />
            </Box>
          ) : (
            ""
          )}

          <Grid container justifyContent="space-around" sx={{ mt:3, mb: 4 }}>
            {Cards.map((ele) => (
              <Grid
                className={classes.weatherCard}
                key={ele.id}
                item
                xs={12}
                md={6}
              >
                {ele.cls ? (
                  <i
                    style={{ fontSize: "3rem", color: colors.primary.main }}
                    className={`fas ${ele.cls}`}
                  />
                ) : (
                  <Avatar
                    sx={{
                      width: "4rem",
                      background: ele.id == 3 ? "#ffffffb3" : "transparent",
                    }}
                    color="primary"
                    src={ele.img}
                  />
                )}

                <Box
                  sx={
                    isMobile
                      ? {
                          width: "100%",
                          justifyContent: "center",
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          marginTop: 2,
                        }
                      : { ml: 2 }
                  }
                >
                  <Typography variant="body2" color="text.primary" gutterBottom>
                    {" "}
                    {ele.title}{" "}
                  </Typography>
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    gutterBottom
                  >
                    {" "}
                    {ele.subTitle}{" "}
                  </Typography>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Container>
      </Box>

      <Box className={classes.Box2}>
        <Container className={classes.Container2}>
          <Typography
            variant="h2"
            color="text.primary"
            align="left"
            sx={{
              mt: 1,
            }}
          >
            Hourly weather in{" "}
            <span style={{ color: theme.palette.primary.main }}>
              {address.display_name}
            </span>
          </Typography>
          <Typography
            sx={{ mb: 3 }}
            variant="subtitle1"
            color="text.primary"
            gutterBottom
          >
            {" "}
            Shows temperature in the next 48hrs{" "}
          </Typography>

          <Carousel
            className={classes.carousel}
            // autoPlay
            infiniteLoop
            thumbWidth={isMobile ? 90 : 20}
            centerSlidePercentage={isMobile ? 90 : 20}
            centerMode
            emulateTouch
          >
            {data.hours.map((ele, idx) => (
              <Box
                key={idx}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  margin: 2,
                  my: 4,
                  padding: 2,
                  borderRadius: 10,
                  borderColor: colors.bg2.border,
                  backgroundColor: colors.bg2.card,
                }}
              >
                <Typography
                  sx={{ color: colors.textSecondary }}
                  align="left"
                  gutterBottom
                >
                  {idx < 21 ? "Today" : "Tomorrow"}
                </Typography>
                <Typography
                  sx={{ color: colors.textSecondary }}
                  align="left"
                  gutterBottom
                >
                  {ele.time}{" "}
                </Typography>
                <Avatar
                  src={ele.icon}
                  sx={{ background: "#2196f3", width: "40px", height: "40px" }}
                />
                <Typography
                  sx={{ color: colors.textMain }}
                  variant="h6"
                  align="left"
                  gutterBottom
                >
                  {ele.weatherDescription}{" "}
                </Typography>
                <Typography
                  sx={{ color: colors.primary.main }}
                  variant="h6"
                  align="left"
                  gutterBottom
                >
                  {ele.temp}{" "}
                </Typography>
              </Box>
            ))}
          </Carousel>
          <Typography
            textAlign={"center"}
            sx={{ mt: "-20px" }}
            variant="body2"
            color="text.primary"
            gutterBottom
          >
            {" "}
            Slide above to show more{" "}
          </Typography>
        </Container>
      </Box>
    </Box>
  ) : (
    <Box className={classes.flxClmn}>
      <CircularProgress />
    </Box>
  );
}
const convertTime = (utc) => {
  var date = new Date(utc * 1000);
  var hours = date.getHours();
  var minutes = "0" + date.getMinutes();
  var seconds = "0" + date.getSeconds();

  var formattedTime =
    hours + ":" + minutes.substr(-2) + ":" + seconds.substr(-2);
  return formattedTime;
};
const getImg = (weather) => {
  let img = "";
  switch (weather) {
    case "Thunderstorm":
      img = "thunder.jpg";
      break;
    case "Drizzle":
      img = "drizzle.jpg";
      break;
    case "Rain":
      img = "rain.jpg";
      break;
    case "Snow":
      img = "snow.jpg";
      break;
    case "Atmosphere":
      img = "atmo.jpg";
      break;
    case "Clear":
      img = "clear.jpg";
      break;
    case "Clouds":
      img = "cloud.jpg";
      break;
  }
  return img;
};

const construct = (daily) => {
  let temp = {};
  temp.requestTime = convertTime(daily.data.current.dt);
  temp.sunrise = convertTime(daily.data.current.sunrise);
  temp.sunset = convertTime(daily.data.current.sunset);

  temp.temperature = daily.data.current.temp;
  temp.wind_deg = daily.data.current.wind_deg;
  temp.wind_speed = daily.data.current.wind_speed + " metre/sec";

  //2xx Thunderstorm, 3xx Drizzle, 5xx Rain, 6xx Snow, 7xx Atmosphere, 800 Clear ,80x Clouds
  //thunder.jpg, drizzle.jpg, rain.jpg, snow.jpg, atmo.jpg, clear.jpg, cloud.jpg,
  temp.icon = `https://openweathermap.org/img/wn/${daily.data.current.weather[0].icon}@2x.png`;
  temp.weather = daily.data.current.weather[0].main;
  temp.weatherDescription = daily.data.current.weather[0].description;
  temp.img = getImg(temp.weather);

  temp.hours = daily.data.hourly.map((ele) => ({
    time: convertTime(ele.dt),
    feels: ele.feels_like,
    humidity: ele.humidity,
    temp: ele.temp,
    wind_speed: ele.wind_speed + " metre/sec",
    weather: ele.weather[0].main,
    weatherDescription: ele.weather[0].description,
    icon: `https://openweathermap.org/img/wn/${ele.weather[0].icon}@2x.png`,
    img: getImg(ele.weather[0].main),
  }));

  temp.daily = daily.data.daily.map((ele) => ({
    time: convertTime(ele.dt),
    feels: ele.feels_like,
    humidity: ele.humidity,
    temp: ele.temp,
    wind_speed: ele.wind_speed + " metre/sec",
    weather: ele.weather[0].main,
    weatherDescription: ele.weather[0].description,
    icon: `https://openweathermap.org/img/wn/${ele.weather[0].icon}@2x.png`,
    img: getImg(ele.weather[0].main),
  }));
  return temp;
};
