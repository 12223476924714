export const exchanges = {
  symbols: {
    AED: "United Arab Emirates Dirham",
    AFN: "Afghan Afghani",
    ALL: "Albanian Lek",
    AMD: "Armenian Dram",
    ANG: "Netherlands Antillean Guilder",
    AOA: "Angolan Kwanza",
    ARS: "Argentine Peso",
    AUD: "Australian Dollar",
    AWG: "Aruban Florin",
    AZN: "Azerbaijani Manat",
    BAM: "Bosnia-Herzegovina Convertible Mark",
    BBD: "Barbadian Dollar",
    BDT: "Bangladeshi Taka",
    BGN: "Bulgarian Lev",
    BHD: "Bahraini Dinar",
    BIF: "Burundian Franc",
    BMD: "Bermudan Dollar",
    BND: "Brunei Dollar",
    BOB: "Bolivian Boliviano",
    BRL: "Brazilian Real",
    BSD: "Bahamian Dollar",
    BTC: "Bitcoin",
    BTN: "Bhutanese Ngultrum",
    BWP: "Botswanan Pula",
    BYN: "New Belarusian Ruble",
    BYR: "Belarusian Ruble",
    BZD: "Belize Dollar",
    CAD: "Canadian Dollar",
    CDF: "Congolese Franc",
    CHF: "Swiss Franc",
    CLF: "Chilean Unit of Account (UF)",
    CLP: "Chilean Peso",
    CNY: "Chinese Yuan",
    COP: "Colombian Peso",
    CRC: "Costa Rican Colón",
    CUC: "Cuban Convertible Peso",
    CUP: "Cuban Peso",
    CVE: "Cape Verdean Escudo",
    CZK: "Czech Republic Koruna",
    DJF: "Djiboutian Franc",
    DKK: "Danish Krone",
    DOP: "Dominican Peso",
    DZD: "Algerian Dinar",
    EGP: "Egyptian Pound",
    ERN: "Eritrean Nakfa",
    ETB: "Ethiopian Birr",
    EUR: "Euro",
    FJD: "Fijian Dollar",
    FKP: "Falkland Islands Pound",
    GBP: "British Pound Sterling",
    GEL: "Georgian Lari",
    GGP: "Guernsey Pound",
    GHS: "Ghanaian Cedi",
    GIP: "Gibraltar Pound",
    GMD: "Gambian Dalasi",
    GNF: "Guinean Franc",
    GTQ: "Guatemalan Quetzal",
    GYD: "Guyanaese Dollar",
    HKD: "Hong Kong Dollar",
    HNL: "Honduran Lempira",
    HRK: "Croatian Kuna",
    HTG: "Haitian Gourde",
    HUF: "Hungarian Forint",
    IDR: "Indonesian Rupiah",
    ILS: "Israeli New Sheqel",
    IMP: "Manx pound",
    INR: "Indian Rupee",
    IQD: "Iraqi Dinar",
    IRR: "Iranian Rial",
    ISK: "Icelandic Króna",
    JEP: "Jersey Pound",
    JMD: "Jamaican Dollar",
    JOD: "Jordanian Dinar",
    JPY: "Japanese Yen",
    KES: "Kenyan Shilling",
    KGS: "Kyrgystani Som",
    KHR: "Cambodian Riel",
    KMF: "Comorian Franc",
    KPW: "North Korean Won",
    KRW: "South Korean Won",
    KWD: "Kuwaiti Dinar",
    KYD: "Cayman Islands Dollar",
    KZT: "Kazakhstani Tenge",
    LAK: "Laotian Kip",
    LBP: "Lebanese Pound",
    LKR: "Sri Lankan Rupee",
    LRD: "Liberian Dollar",
    LSL: "Lesotho Loti",
    LTL: "Lithuanian Litas",
    LVL: "Latvian Lats",
    LYD: "Libyan Dinar",
    MAD: "Moroccan Dirham",
    MDL: "Moldovan Leu",
    MGA: "Malagasy Ariary",
    MKD: "Macedonian Denar",
    MMK: "Myanma Kyat",
    MNT: "Mongolian Tugrik",
    MOP: "Macanese Pataca",
    MRO: "Mauritanian Ouguiya",
    MUR: "Mauritian Rupee",
    MVR: "Maldivian Rufiyaa",
    MWK: "Malawian Kwacha",
    MXN: "Mexican Peso",
    MYR: "Malaysian Ringgit",
    MZN: "Mozambican Metical",
    NAD: "Namibian Dollar",
    NGN: "Nigerian Naira",
    NIO: "Nicaraguan Córdoba",
    NOK: "Norwegian Krone",
    NPR: "Nepalese Rupee",
    NZD: "New Zealand Dollar",
    OMR: "Omani Rial",
    PAB: "Panamanian Balboa",
    PEN: "Peruvian Nuevo Sol",
    PGK: "Papua New Guinean Kina",
    PHP: "Philippine Peso",
    PKR: "Pakistani Rupee",
    PLN: "Polish Zloty",
    PYG: "Paraguayan Guarani",
    QAR: "Qatari Rial",
    RON: "Romanian Leu",
    RSD: "Serbian Dinar",
    RUB: "Russian Ruble",
    RWF: "Rwandan Franc",
    SAR: "Saudi Riyal",
    SBD: "Solomon Islands Dollar",
    SCR: "Seychellois Rupee",
    SDG: "Sudanese Pound",
    SEK: "Swedish Krona",
    SGD: "Singapore Dollar",
    SHP: "Saint Helena Pound",
    SLL: "Sierra Leonean Leone",
    SOS: "Somali Shilling",
    SRD: "Surinamese Dollar",
    STD: "São Tomé and Príncipe Dobra",
    SVC: "Salvadoran Colón",
    SYP: "Syrian Pound",
    SZL: "Swazi Lilangeni",
    THB: "Thai Baht",
    TJS: "Tajikistani Somoni",
    TMT: "Turkmenistani Manat",
    TND: "Tunisian Dinar",
    TOP: "Tongan Paʻanga",
    TRY: "Turkish Lira",
    TTD: "Trinidad and Tobago Dollar",
    TWD: "New Taiwan Dollar",
    TZS: "Tanzanian Shilling",
    UAH: "Ukrainian Hryvnia",
    UGX: "Ugandan Shilling",
    USD: "United States Dollar",
    UYU: "Uruguayan Peso",
    UZS: "Uzbekistan Som",
    VEF: "Venezuelan Bolívar Fuerte",
    VND: "Vietnamese Dong",
    VUV: "Vanuatu Vatu",
    WST: "Samoan Tala",
    XAF: "CFA Franc BEAC",
    XAG: "Silver (troy ounce)",
    XAU: "Gold (troy ounce)",
    XCD: "East Caribbean Dollar",
    XDR: "Special Drawing Rights",
    XOF: "CFA Franc BCEAO",
    XPF: "CFP Franc",
    YER: "Yemeni Rial",
    ZAR: "South African Rand",
    ZMK: "Zambian Kwacha (pre-2013)",
    ZMW: "Zambian Kwacha",
    ZWL: "Zimbabwean Dollar",
  },

  rates: {
    AED: 4.169641,
    AFN: 118.635903,
    ALL: 121.403941,
    AMD: 546.462632,
    ANG: 2.035857,
    AOA: 625.421914,
    ARS: 116.693787,
    AUD: 1.581168,
    AWG: 2.043955,
    AZN: 1.93313,
    BAM: 1.954445,
    BBD: 2.280883,
    BDT: 97.075586,
    BGN: 1.957883,
    BHD: 0.427997,
    BIF: 2253.572521,
    BMD: 1.135215,
    BND: 1.535312,
    BOB: 7.777587,
    BRL: 6.396977,
    BSD: 1.129599,
    BTC: 0.00002707325,
    BTN: 83.940498,
    BWP: 13.173662,
    BYN: 2.924327,
    BYR: 22250.219594,
    BZD: 2.276986,
    CAD: 1.435831,
    CDF: 2278.376835,
    CHF: 1.043365,
    CLF: 0.034195,
    CLP: 943.531164,
    CNY: 7.24006,
    COP: 4557.569298,
    CRC: 725.425771,
    CUC: 1.135215,
    CUP: 30.083205,
    CVE: 110.186892,
    CZK: 24.422625,
    DJF: 201.107442,
    DKK: 7.4391,
    DOP: 64.807061,
    DZD: 158.36574,
    EGP: 17.853769,
    ERN: 17.028318,
    ETB: 56.041474,
    EUR: 1,
    FJD: 2.422664,
    FKP: 0.856383,
    GBP: 0.835235,
    GEL: 3.513518,
    GGP: 0.856383,
    GHS: 6.986248,
    GIP: 0.856383,
    GMD: 59.939696,
    GNF: 10313.589056,
    GTQ: 8.720818,
    GYD: 236.342465,
    HKD: 8.852301,
    HNL: 27.736339,
    HRK: 7.514667,
    HTG: 112.938942,
    HUF: 358.96657,
    IDR: 16254.863868,
    ILS: 3.535992,
    IMP: 0.856383,
    INR: 84.32095,
    IQD: 1648.741265,
    IRR: 47962.845996,
    ISK: 146.045258,
    JEP: 0.856383,
    JMD: 174.456334,
    JOD: 0.804892,
    JPY: 131.297292,
    KES: 127.988174,
    KGS: 96.265403,
    KHR: 4603.236926,
    KMF: 492.041323,
    KPW: 1021.693669,
    KRW: 1359.482687,
    KWD: 0.343573,
    KYD: 0.941333,
    KZT: 491.901308,
    LAK: 12708.491393,
    LBP: 1708.289053,
    LKR: 229.175545,
    LRD: 167.018573,
    LSL: 17.754956,
    LTL: 3.351995,
    LVL: 0.68668,
    LYD: 5.195617,
    MAD: 10.488265,
    MDL: 20.203578,
    MGA: 4493.144966,
    MKD: 61.571354,
    MMK: 2008.498845,
    MNT: 3244.88195,
    MOP: 9.075467,
    MRO: 405.271662,
    MUR: 49.61483,
    MVR: 17.539491,
    MWK: 922.237258,
    MXN: 23.1561,
    MYR: 4.778092,
    MZN: 72.460515,
    NAD: 17.749085,
    NGN: 469.082728,
    NIO: 39.994649,
    NOK: 10.032624,
    NPR: 134.304796,
    NZD: 1.675731,
    OMR: 0.43707,
    PAB: 1.129599,
    PEN: 4.484621,
    PGK: 3.967188,
    PHP: 58.274691,
    PKR: 199.494285,
    PLN: 4.545657,
    PYG: 7855.731345,
    QAR: 4.133292,
    RON: 4.946699,
    RSD: 117.49771,
    RUB: 85.676798,
    RWF: 1172.16909,
    SAR: 4.261145,
    SBD: 9.180913,
    SCR: 17.01007,
    SDG: 496.655328,
    SEK: 10.291135,
    SGD: 1.539721,
    SHP: 1.563646,
    SLL: 12867.664942,
    SOS: 662.965626,
    SRD: 24.143788,
    STD: 23496.664417,
    SVC: 9.883994,
    SYP: 2852.220212,
    SZL: 17.644992,
    THB: 38.16024,
    TJS: 12.759255,
    TMT: 3.973253,
    TND: 3.270606,
    TOP: 2.593398,
    TRY: 15.667299,
    TTD: 7.667665,
    TWD: 31.392105,
    TZS: 2607.211838,
    UAH: 31.059783,
    UGX: 4004.561306,
    USD: 1.135215,
    UYU: 50.54617,
    UZS: 12220.497315,
    VEF: 242743336875.57742,
    VND: 25758.034825,
    VUV: 128.566143,
    WST: 2.951754,
    XAF: 655.492344,
    XAG: 0.050749,
    XAU: 0.000633,
    XCD: 3.067976,
    XDR: 0.807827,
    XOF: 655.492344,
    XPF: 119.793602,
    YER: 284.087503,
    ZAR: 17.69602,
    ZMK: 10218.297011,
    ZMW: 19.040103,
    ZWL: 365.538859,
  },
};
