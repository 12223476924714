import React, { useState, useEffect } from "react";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import { makeStyles } from "@mui/styles";
import { useTheme } from "@emotion/react";
import { Grid } from "@mui/material";

import { posts } from "../../Data/Blogs";
import { useMode } from "../../contexts/ModeProvider";
const useStyles = makeStyles({
  root: {
    paddingBottom: 40,
    paddingTop: 40,
    position: "relative",
    backgroundColor: ({ colors }) => colors.bg1.bg,
    zIndex: 1,
    "& .bgSection": {
      position: "absolute",
      width: "100%",
      height: "100%",
      right: "100%",
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",

      zIndex: -1,
      transition: "all 1s",
    },
    "& .bgBlk": {
      position: "absolute",
      right: "100%",
      width: "100%",
      height: "100%",
      zIndex: 0,
      backgroundColor: "#0a18289e",
    },
    "& .artcl:hover .bgBlk": {
      right: 0,
    },
    "& .artcl:hover .bgSection": {
      right: 0,
    },
  },
  article: {
    border: ({ colors }) => `1px solid ${colors.bg2.cardBorder}`,
    background: ({ colors }) => colors.bg2.card,
    transition: "all .4s",
    transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    position: "relative",
    cursor: "pointer",
    display: "flex",
    justifyContent: "space-between",
    borderRadius: 10,
    flexDirection: ({ isMobile }) => (isMobile ? "column" : "row"),

    "& .dv": {
      padding: 20,
      width: ({ isMobile }) => (isMobile ? "auto" : "100%"),
    },

    "&:hover, &:focus": {
      background: ({ colors }) => colors.bg2.cardHover,

      transform: ({ isMobile }) => (isMobile ? "" : "scale(1.1)"),
      boxShadow: "rgb(90 105 120 / 20%)  10px 1px 23px 0px",
    },
    "&:hover .readmore, &:focus .readmore": {
      borderColor: ({ colors }) => colors.secondary.main,
      animation: "bounce",
      animationDuration: " 2s",
    },
    "& .readmore": {
      border: "1px solid #ff910000",
      transition: "all .3s",
    },
  },
});

export default function Articles() {
  const { colors, isMobile } = useMode();
  const classes = useStyles({ colors, isMobile });
  const [blogs,setBlogs ] = React.useState(posts);
  const [showAll, setShowAll] = React.useState(true);
  useEffect(() => {
    if(showAll)
      setBlogs(posts.slice(0,6));
    else setBlogs(posts);
  }, [showAll])
  const goTo = (url ) => {
    console.log("id: " , url);
      window.open(url, '_blank').focus();
  }
  const theme = useTheme();


  return (
    <div className={classes.root} id="articles">
      <div className="bgSection"></div>
      <div className="bgBlk"></div>
      <Container className="myArticles">
        <Typography
          variant="h2"
          color="primary"
          align="left"
          gutterBottom
          sx={{
            fontSize: "0.875rem",
            lineHeight: "1.5",
            fontWeight: "700",
          }}
        >
          Blog
        </Typography>
        <Typography
          variant="h2"
          color="text.primary"
          align="left"
          sx={{
            mt: 1,
          }}
        >
          Explained articles about new{" "}
          <span style={{ color: theme.palette.primary.main }}>
            {" "}
            technologies{" "}
          </span>{" "}
          trending in the world.{" "}
        </Typography>
        <Typography
          color="text.secondary"
          variant="subtitle1"
          sx={{
            mb: 5,
            mt: 1,
          }}
        >
          Articles in Arabic about newest technology topics.{" "}
        </Typography>

        <Grid container spacing={4}>
          
          {blogs.map((ele) => (
            <Grid
              key={ele._id}
              data-img={ele.imgStr}
              className=" artcl"
              item
              xs={12}
              md={6}
              onClick={() => goTo(ele.hasLink ? ele.link : `/one/?id=${ele._id}`)}
            >
              <Box className={classes.article}>
                <img
                  style={{ width: isMobile ? "100%" : "160px" }}
                  src={ele.imgStr}
                  className="img"
                />
                {/* <div className="white"> </div> */}
                <div className="dv">
                  <Typography
                    variant="h4"
                    align="left"
                    color="text.primary"
                    fontWeight={700}
                    gutterBottom
                  >
                    {ele.title}
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={ele.eng ? {} : { direction: "rtl", textAlign: "right" }}
                    align="left"
                    color="text.secondary"
                    gutterBottom
                  >
                    {ele.snippet}{" "}
                  </Typography>
                  <Button
                    className="readmore"
                    variant="text"
                    component="a"
                    color="primary"
                  >
                    Read More
                  </Button>
                </div>
              </Box>
            </Grid>
          ))}
        </Grid>
        <Box
          sx={{
            width: "100%",
            padding: 1,
            display: "flex",
            justifyContent: "center",
            backgroundColor: colors.bg2.bg,
            mt: 2,
            borderRadius: "10px",
            cursor: "pointer",
          }}
          onClick={() => setShowAll( !showAll)}
        >
        {showAll ?  
        <Typography  color="text.primary" > Show all posts </Typography>
        
        : 
        <Typography  color="text.primary" > Show less </Typography>
        
      }


        </Box>

        

      </Container>
    </div>
  );
}
