import React, { useState, useEffect } from "react";
import Typography from "@mui/material/Typography";
import { Paper, Box, Grid, Divider, CircularProgress } from "@mui/material";
import Container from "@mui/material/Container";
import { makeStyles } from "@mui/styles";
import { useLocation, useHistory } from "react-router";
import { posts } from "../../Data/Blogs";
import { useMode } from "../../contexts/ModeProvider";
import Custom from "./CustomPosts/Custom";

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  imgContainer: {},
  container: {
    "& p,span": {
      fontSize: "20px !important",
      textAlign: "right !important",
      direction: "rtl",
      color: ({ colors }) => colors.textMain + " !important",
    },
  },
});

export default function Article() {
  const { colors } = useMode();
  const classes = useStyles({ colors });

  const [post, setPost] = React.useState();

  const location = useLocation();
  const history = useHistory();
  useEffect(() => {
    const fun = async () => {
      const id = location.search.replace("?id=", "");

      if (id.length !== 24) {
        history.push("/");
      } else {
        try {
          const pos = posts.find((ele) => ele._id === id);
          // const pos = await axios.get("/blogs/one/" + id);
          setPost(pos);
          console.log("post: ", pos);
        } catch (er) {
          console.log("error: ", er);
        }
      }
    };
    fun();
  }, []);
  // const { title, snippet, body, imgStr } = post;
  return (
    <Container className={classes.root}>
      {post ? (
        <>
          <Paper
            sx={{
              position: "relative",
              backgroundColor: "#f6fdfe",
              color: "#fff",

              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
              backgroundImage: `url(${post.imgStr})`,
            }}
          >
            {
              <img
                style={{ display: "none" }}
                src={post.imgStr}
                alt={post.imgStr}
              />
            }
            <Box
              sx={{
                position: "absolute",
                top: 0,
                bottom: 0,
                right: 0,
                left: 0,
                backgroundColor: "rgba(0,0,0,.3)",
              }}
            />
            <Grid container>
              <Grid item md={6} style={{ backgroundColor: "#053b86b3" }}>
                <Box
                  sx={{
                    position: "relative",
                    p: { xs: 3, md: 6 },
                    pr: { md: 0 },
                  }}
                >
                  <Typography
                    component="h1"
                    variant="h3"
                    color="inherit"
                    gutterBottom
                  >
                    {post.title}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Paper>

          <div
            style={{ padding: 10, minHeight: "90vh", color: colors.textMain }}
            className={post.custom ? "" : "article"}
          >
            {post.ismarkup ? (
              <div
                className={classes.container}
                dangerouslySetInnerHTML={{ __html: post.body }}
              />
            ) : post.custom ? (
              <Custom _id={post._id} />
            ) : (
              post.body.split("\n").map((i, key) => {
                return (
                  <p style={{ direction: "rtl", textAlign: "right" }} key={key}>
                    {i}
                  </p>
                );
              })
            )}
          </div>
          <Divider />
        </>
      ) : (
        <CircularProgress />
      )}
    </Container>
  );
}
