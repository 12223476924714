import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import $ from "jquery";

import { ModeProvider, useMode } from "./contexts/ModeProvider";
import { initBlast } from "./lib/Blast";
import Resume from "./pages/Resume";
import CV from "./pages/CV";
import Projects from "./pages/Projects";
import TestPG from "./pages/Test";
import Home from "./pages/Home";
import Articles from "./pages/Articles/Index";
import Article from "./pages/Articles/Article";
import Layout from "./Components/Layout";
import IP from "./Tools/Ip";
import Game from "./Tools/Game";
import Weather from "./pages/Weather";
import Rubiks from "./pages/Rubiks";
import NewYear from './pages/newYear'
import ImgGenerator from "./pages/ImgGenerator";
import RateExchange from "./pages/RateExchange";
import PdfSheet from "./pages/PdfSheet";

// const theme = createTheme({
//   palette: {
//     primary: blue,
//     secondary: orange,
//     background: {
//       paper: "white",
//       default: "#fcf7f5",
//     },
//     text: {
//       primary: "#414756",
//     },
//   },
// });

function App() {

  useEffect(() => {
    window.jquery = window.$ = $;
    initBlast($, window, document);

    
  }, []);
  return (
    <ModeProvider>
      <Router>
        <Layout>
          <Switch>
            <Route exact path="/">
              <Home />
            </Route>
            <Route path="/articles">
              <Articles />
            </Route>
            <Route path="/one">
              <Article />
            </Route>
            <Route path="/message">
              <Resume />
            </Route>
            <Route path="/projects">
              <Projects />
            </Route>
            <Route path="/test">
              <TestPG content="hiii" />
            </Route>
            
            <Route path="/newYear">
              <NewYear />
            </Route>
            <Route path="/rubiks">
              <Rubiks />
            </Route>
            <Route path="/alpacagenerator">
              <ImgGenerator />
            </Route>
              <Route path="/pdf">
              <PdfSheet />
            </Route>
            
            <Route path="/weather">
              <Weather />
            </Route>
            <Route path="/game">
              <Game />
            </Route>
            <Route path="/exchangeRate">
              <RateExchange />
            </Route>
            <Route path="/cv">
              <CV />
            </Route>
            {/* <Route path="/ip">
              {info ? (
                <IP info={info} setInfo={setInfo} />
              ) : (
                <CircularProgress />
              )}
            </Route> */}
          </Switch>
        </Layout>
      </Router>
    </ModeProvider>
  );
}

export default App;
