import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  myBtn: {
    position: "relative",
    "& .wrapper": {
      position: "absolute",
      width: "0",
      height: "100%",
      right: "100%",
      backgroundColor: theme.palette.secondary.main,
      transition: "all .2s",
    },
    "&:hover .wrapper": {
      right: 0,
      opacity: 1,
      width: "100%",
    },
    "&:hover .text": {
      color: "white",
      zIndex: "999",
    },
  },
}));

export default function SaderBtn({ text, ...props }) {
  const classes = useStyles();
  return (
    <Button variant="outlined" {...props} className={classes.myBtn} color="secondary">
      <span className="wrapper" />
      <span className="text"> {text}</span>
    </Button>
  );
}
