import React, { useState, useEffect } from "react";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import { useTheme, makeStyles } from "@mui/styles";
import { TextField, InputAdornment, Snackbar } from "@mui/material";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import axios from "axios";
import useMediaQuery from "@mui/material/useMediaQuery";

const useStyles = makeStyles({
  root: {
    backgroundColor: "white",
    padding: 20,
  },
});
export default function Resume() {
  const classes = useStyles();
  const theme = useTheme();

  const isMdUp = useMediaQuery(theme.breakpoints.up("md"));

  const [name, setName] = React.useState();
  const [email, setEmail] = React.useState();
  const [whatsapp, setWhatsapp] = React.useState();
  const [message, setMessage] = React.useState();
  const [error, setError] = React.useState();
  const [msg, setMsg] = React.useState("");
  

  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const sndMsg = async () => {
    try {
      setError(null);
      const result = await axios.post("https://addmsg.glitch.me/addmsg", {
        name,
        email,
        whatsapp,
        message,
      });
      setMsg("Done !");
      handleClick();

    } catch (ex) {
      setError("Some Error Happend ! Please try again later");
    }
  };
  return (
    <Container className={classes.root}>

      <Typography variant="h4" align="center" color="primary">
        Get In Touch !{" "}
      </Typography>
      <Typography variant="body2" align="center" gutterBottom>
        {" "}
        I will receive your message and reply ASAP.{" "}
      </Typography>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          width: "100%",
          mt: 2,
          flexDirection: isMdUp ? "row": "column"
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-around",
            width: isMdUp ? "35%" : "100%",
            mr: isMdUp ? 3 : 0,
          }}
        >
          <TextField
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <AccountBoxIcon color="primary" />
                </InputAdornment>
              ),
            }}
            sx={{ mb: 2 }}
            label="Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            variant="outlined"
            fullWidth
            required
          />
          <TextField
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <AlternateEmailIcon color="primary" />
                </InputAdornment>
              ),
            }}
            sx={{ mb: 2 }}
            label="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            variant="outlined"
            fullWidth
            required
          />
          <TextField
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <WhatsAppIcon color="primary" />
                </InputAdornment>
              ),
            }}
            sx={{ mb: 2 }}
            label="Whatsapp"
            value={whatsapp}
            onChange={(e) => setWhatsapp(e.target.value)}
            variant="outlined"
            fullWidth
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-around",
            width: isMdUp ? "35%" : "100%",
          }}
        >
          <TextField
            sx={{ mb: 2 }}
            label="Message"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            variant="outlined"
            fullWidth
            rows={8}
            multiline
            required
          />
        </Box>
      </Box>
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <Button variant="contained" onClick={sndMsg} color="primary">
          Send Message{" "}
        </Button>
        {error ? { error } : ""}
      </Box>
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        message={msg}
      />
    </Container>
  );
}
