import React, {useState, useEffect} from "react";
import Typography from "@mui/material/Typography";
import {IconButton, Stack} from "@mui/material";
import TelegramIcon from "@mui/icons-material/Telegram";
import FacebookIcon from "@mui/icons-material/Facebook";
import GitHubIcon from "@mui/icons-material/GitHub";
import LinkedInIcon from "@mui/icons-material/LinkedIn";

import Container from "@mui/material/Container";
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  root: {},
})

export default function SocialStack({iconColor}) {
  const classes = useStyles();

  const goTo = (path) => window.open(path, "_blank").focus();
  return (
    <Stack direction="row" spacing={1}>
    <IconButton
      aria-label="Telegram Channel"
      sx={{color: iconColor}}
      onClick={() => goTo("https://t.me/sadertony")}
    >
      <TelegramIcon />
    </IconButton>
    <IconButton
      aria-label="Facebook"
      sx={{color: iconColor}}

      onClick={() => goTo("https://www.facebook.com/tonysader.s")}
    >
      <FacebookIcon />
    </IconButton>
    <IconButton
      aria-label="LinkedIn"
      sx={{color: iconColor}}

      onClick={() => goTo("https://www.linkedin.com/in/antoine-sader/")}
    >
      <LinkedInIcon />
    </IconButton>
    <IconButton
      aria-label="Github"
      sx={{color: iconColor}}

      onClick={() => goTo("https://github.com/tonysader")}
    >
      <GitHubIcon />
    </IconButton>
  </Stack>
  );
}
