import React, { useState, useEffect } from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import { makeStyles } from "@mui/styles";
import { useMode } from "../../../contexts/ModeProvider";
import Video from "../../../Components/Video";
import {
  video1,
  video2,
  video3,
  video4,
  video5,
} from "../../../assets/deepfake/";

const useStyles = makeStyles({
  root: {},
});

export default function DeepFake() {
  const { isMobile } = useMode();
  const classes = useStyles();
  const CustomBox = ({ src, title, body }) => (
    <Box
      sx={
        isMobile
          ? {}
          : {
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }
      }
    >
      {src ? (
        <Video
          src={src}
          style={
            isMobile
              ? { height: "30vh", marginBottom: 30, marginTop: 30 }
              : { height: "55vh", width: "400vw", marginRight: 30 }
          }
        />
      ) : (
        ""
      )}
      <Box>
        <Typography variant="h5" fontWeight={800} color="primary" gutterBottom>
          {title}{" "}
        </Typography>
        <Typography variant="body1" color="text.primary" gutterBottom>
          {body}{" "}
        </Typography>
      </Box>
    </Box>
  );
  return (
    <Box className={classes.root}>
      <Container>
        <CustomBox
          src={video1}
          title="Evil AI:"
          body={`
          The term “Evil Artificial Intelligence” is now trending everywhere especially 
          with all the progress around us.
          
          When I first hear this terminology “Evil AI” it occurs to me the “Star Wars” movie 
          or  “the Terminator” among many other movies which illustrate this idea. 
          
          Maybe, you think about a robot that was invented by humans, then the robot
           got out of control, and started to kill people, and tried to dominate the world.
          
          Unfortunately, as bad as it may sound, it is not the worst scenario of evil AI.
          
          `}
        />
        <CustomBox
          src={video2}
          title="TikTok:"
          body={`
          Is there anything more dangerous than this?
Yes, it’s DeepFake.

A quick fact, based on the statistics from 2021 of the top websites in the world, 
google is no longer on the top, it is TikTok instead.

Why did I mention TikTok? 
Because if you used TikTok before, then you definitely used Deep fake, 
which means you are now part of the evil AI in the world!


Why?
Because when you edit your video, use filters to change the characteristics of 
your face to someone else’s , you are using DeepFake.
          `}
        />
        <CustomBox
          src={video3}
          title="DeepFake:"
          body={`
          DeepFake is a technology that can be used to modify your videos, photos, 
and sound and replace them with someone else's.

The word “DEEP” is the shortened form of “deep learning”, which is a huge subject, 
what we need to know for now is that when you swap your face with a famous 
person’s face. You are swapping using deep learning.
          `}
        />

        <CustomBox
          src={video4}
          title="Problems:"
          body={`
          Theft: I will give you a simple example here, because I don’t really have evil ideas, 
but imagine yourself in Germany and your parents are in Syria, 
you wake up and you received a video message, you see your mum’s picture, 
within the video she is saying to you:
“Son, I had to change my WhatsApp number, 
and I need you to send me 500 Syrian Pound to my bank account as soon as possible”.

So you think:
“It is my mum, no doubt !” 
so you send the money to a fake account.


Misleading public opinion: 
imagine Joe Biden before few days of the election, 
has a video showing the public hate speech, 
therefore, next day he will loose the election despite the fact that he didn’t say
anything.

          `}
        />
        <CustomBox
          src={video5}
          title="Types:"
          body={`
          - Face swapping: It is becoming a common feature among many applications 
          on our smartphones like snapchat, messenger, instagram and TikTok. 
          Through this feature we can change the characteristics of someone’s face 
          to another’s or even to an animal’s facial characteristics 
          
          - Puppeteering: manipulates a 3d object like a puppet, to create an illusion that
          this puppet is alive
          
          - Lip-Sync: a great example is the video of Obama giving a speech 
          (of which he did not) with his lips moving as if it was real.
          
          - Image Synthesis: you can visit the website thispersondoesnotexist.com 
          that generates pictures of people who don’t exist.  This website was developed 
          by Nvidia team who takes photos of different people from the website Flickr 
          and trained an algorithm  to produce pictures of people don’t exist
          
          - Text Generation: In any mobile with the autocomplete feature  enabled. 
          You can click on the suggested words and eventually you can generate 
          articles without your interverance.
                    
          `}
        />
        <CustomBox
          title="Bright side:"
          body={`
          Is there a bright side?
          Yes, of course, many engineers who value positive benefits of this technology 
          have started to use the term: “Artificial Intelligence Generated Synthetic Media” 
          to avoid negative effects under the name “deepfake”.

          “SEEING IS NO LONGER BELIEVING.”
                    
          `}
        />
      </Container>
    </Box>
  );
}
