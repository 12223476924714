import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";
import { CircularProgress, Divider } from "@mui/material";
import { posts } from "../../Data/Blogs";

import MainFeaturedPost from "../../Components/MainFeaturedPost";
import FeaturedPost from "../../Components/FeaturedPost";

const useStyles = makeStyles({
  root: {},
});

export default function Index() {
  const classes = useStyles();
  const [allPosts, setAllPosts] = React.useState();
  const [mainFeaturedPost, setMainFeaturedPost] = React.useState();

  useEffect(() => {
    const fun = async () => {
      try {
        setAllPosts(posts);
        setMainFeaturedPost(posts.find((ele) => ele.isFeatured));
      } catch (er) {
        console.log("error: ", er);
      }
    };
    fun();
  }, []);

  return (
    <Container className={classes.root}>
      {mainFeaturedPost ? <MainFeaturedPost post={mainFeaturedPost} /> : ""}
      <Typography variant="h6" gutterBottom>
        My articles
      </Typography>
      <Divider />
      <Grid container spacing={4}>
        {allPosts ? (
          allPosts.map((post) => <FeaturedPost key={post.title} post={post} />)
        ) : (
          <CircularProgress />
        )}
      </Grid>
    </Container>
  );
}
