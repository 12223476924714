import React, { useState, useEffect } from "react";
import { BsFillPlayFill, BsPauseFill } from "react-icons/bs";

import "./Video.css";

export default function Video({ src, autoPlay, style }) {
  const [playVideo, setPlayVideo] = React.useState(false);
  const vidRef = React.useRef();

  return (
    <div className="app__video" style={style}>
      <video
        ref={vidRef}
        src={src}
        type="video/mp4"
        loop
        controls={false}
        autoPlay={autoPlay}
      />
      <div className="app__video-overlay flex__center">
        <div
          className="app__video-overlay_circle flex__center"
          onClick={() => {
            setPlayVideo(!playVideo);
            if (playVideo) {
              vidRef.current.pause();
            } else {
              vidRef.current.play();
            }
          }}
        >
          {playVideo ? (
            <BsPauseFill color="#fff" fontSize={30} />
          ) : (
            <BsFillPlayFill color="#fff" fontSize={30} />
          )}
        </div>
      </div>
    </div>
  );
}
