import React, { useState, useEffect } from "react";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import { useTheme, makeStyles } from "@mui/styles";
import { TextField, InputAdornment, Snackbar } from "@mui/material";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import axios from "axios";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useMode } from "../../contexts/ModeProvider";

const useStyles = makeStyles({
    root: {
      backgroundColor: ({colors}) => colors.bg2.bg,
      padding: 20,
      paddingTop: 0,
    },
  });
export default function Message() {
  const {colors} = useMode();

  const classes = useStyles({colors});
  const theme = useTheme();

  const isMdUp = useMediaQuery(theme.breakpoints.up("md"));

  const [name, setName] = React.useState();
  const [email, setEmail] = React.useState();
  const [whatsapp, setWhatsapp] = React.useState();
  const [message, setMessage] = React.useState();
  const [error, setError] = React.useState();
  const [msg, setMsg] = React.useState("");
  

  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const sndMsg = async () => {
    try {
      setError(null);
      const result = await axios.post("https://addmsg.glitch.me/addmsg", {
        name,
        email,
        whatsapp,
        message,
      });
      setMsg("Done !");
      handleClick();

    } catch (ex) {
      setError("Some Error Happend ! Please try again later");
    }
  };
  return (
    <Box className={classes.root}>
      <svg style={{animation: "svgAnimation 2s infinite"}} preserveAspectRatio="none" viewBox="0 0 100 102" height="75" width="100%" version="1.1" xmlns="http://www.w3.org/2000/svg" className="svgcolor-light">
          <path d="M0 0 L50 100 L100 0 Z" fill={colors.bg1.bg} stroke={colors.bg1.bg}></path>
        </svg>

    <Container sx={{pt: 1}} className="messaging">


      <Typography
          variant="h2"
          color="primary"
          align="center"
          gutterBottom
          sx={{
            fontSize: "0.875rem",
            lineHeight: "1.5",
            fontWeight: "700",
          }}
        >
          Contact
        </Typography>
        <Typography
          variant="h2"
          color="text.primary"
          align="center"
          sx={{
            mt: 1,
          }}
        >
          Get in {" "}
          <span style={{ color: theme.palette.primary.main }}>
            TOUCH
          </span>
        </Typography>
        <Typography
          align="center"
          color="text.secondary"
          variant="subtitle1"
          sx={{
            mb: 5,
            mt: 1,
          }}
        >
          I will receive your message and reply ASAP.
        </Typography>

      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          width: "100%",
          my: 2,
          flexDirection: isMdUp ? "row": "column",
          padding: isMdUp ? 3: 0,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-around",
            width: isMdUp ? "35%" : "100%",
            mr: isMdUp ? 3 : 0,
          }}
        >
          <TextField
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <AccountBoxIcon color="primary" />
                </InputAdornment>
              ),
            }}
            sx={{ mb: 2 }}
            label="Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            variant="outlined"
            fullWidth
            required
          />
          <TextField
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <AlternateEmailIcon color="primary" />
                </InputAdornment>
              ),
            }}
            sx={{ mb: 2 }}
            label="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            variant="outlined"
            fullWidth
            required
          />
          <TextField
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <WhatsAppIcon color="primary" />
                </InputAdornment>
              ),
            }}
            sx={{ mb: 2 }}
            label="Whatsapp"
            value={whatsapp}
            onChange={(e) => setWhatsapp(e.target.value)}
            variant="outlined"
            fullWidth
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-around",
            width: isMdUp ? "35%" : "100%",
          }}
        >
          <TextField
            sx={{ mb: 2 }}
            label="Message"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            variant="outlined"
            fullWidth
            rows={8}
            multiline
            required
          />
        </Box>
      </Box>
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <Button variant="contained" onClick={sndMsg} color="primary">
          Send Message{" "}
        </Button>
        {error ? { error } : ""}
      </Box>
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        message={msg}
      />
    </Container>
    </Box>
  );
}
