import React, { useEffect } from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import { makeStyles } from "@mui/styles";
import $ from "jquery";
import { useTheme } from "@emotion/react";

import meImg from "../../assets/me.png";
import Animy from "../Animy";
import { useMode } from "../../contexts/ModeProvider";
import { KeyboardArrowDown, DarkMode, LightMode } from "@mui/icons-material";
import "./intro.css";
import { Zoom } from "@mui/material";
const useStyles = makeStyles((theme) => ({
  intro: {
    backgroundColor: ({ colors }) => colors.bg1.bg,
    minHeight: "99vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    color: theme.palette.text.primary,
    position: "relative",
  },
  introDiv: {
    width: ({ isMobile }) => (isMobile ? "100%" : "70%"),
    // height: ({ isMobile }) => (isMobile ? "99vh" : "auto"),
    textAlign: ({ isMobile }) => (isMobile ? "center" : "inherit"),
  },
  main: {
    display: "flex !important",
    justifyContent: "space-between",
    alignItems: "center",
    padding: 50,
    paddingTop: 0,
    minHeight: "40vh",
    position: "relative",
    zIndex: 99,
    "& .showimg:hover img": {
      opacity: 1,
    },
  },
  myImg: {
    width: "33vw",
    position: "absolute",
    right: 10,
    opacity: 0.7,
    transition: "all .5s",
  },
  myBtn: {
    position: "relative",
    "& .wrapper": {
      position: "absolute",
      width: "0",
      height: "100%",
      right: "100%",
      backgroundColor: theme.palette.secondary.main,
      transition: "all .2s",
    },
    "&:hover .wrapper": {
      right: 0,
      opacity: 1,
      width: "100%",
    },
    "&:hover .text": {
      color: "white",
      zIndex: "999",
    },
  },
  scrollDown: {
    zIndex: 99,
    position: "absolute",
    bottom: "2rem",
    display: "flex",
    boxOrient: "vertical",
    boxDirection: "normal",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",

    animationName: "bounce-1",
    animationTimingFunction: "linear",
    animationDuration: "1s",
    animationIterationCount: "infinite",
    "& span": {
      writingMode: "vertical-rl",
      textOrientation: "mixed",
      fontSize: 12,
    },
  },
  scrollRight: {
    right: 10,
  },
  scrollLeft: {
    left: 10,
  },
}));

export default function Intro({ scrollToSection }) {
  const { isMobile, colors, mode, toggleThemeMode } = useMode();

  const classes = useStyles({ isMobile, colors });
  const theme = useTheme();
  useEffect(() => {
    $(".showimg").hover(() => {
      $("." + classes.myImg).css("opacity", "1");
      window.setTimeout(() => {
        $("." + classes.myImg).css("opacity", "0.5");
      }, 1000);
    });
  }, []);

  return (
    <Box className={classes.intro}>
      <Container className={classes.main}>
        <Box className={classes.introDiv}>
          <Zoom in={true} style={{ transitionDelay: "500ms" }}>
            <Typography
              variant="h2"
              sx={{
                fontWeight: 600,
                fontSize: isMobile
                  ? "clamp(2.625rem, 3.2857rem + 3.5714vw, 4rem)"
                  : "3.75rem",
                lineHeight: isMobile ? "1.11429" : "5.7rem",
                marginBottom: isMobile ? 3 : 0,
              }}
              className="showimg"
              color="text.primary"
            >
              <Animy
                animColor={theme.palette.secondary.main}
                alwaysColor={colors.textMain}
                content="Hi,"
              />
              I'm{" "}
              <Animy
                css={{ fontWeight: 900 }}
                alwaysColor={theme.palette.primary.main}
                animColor={theme.palette.secondary.main}
                content="Antoin Sader"
              />
            </Typography>
          </Zoom>
          <Zoom in={true} style={{ transitionDelay: "1s" }}>
            <Typography variant="body1" color="text.primary" gutterBottom>
              <div className="typewriter_container">
                <div
                  className="typed-out"
                  style={{
                    marginBottom: isMobile ? 16 : "0.35em",
                    fontStyle: "italic",
                    fontSize: isMobile ? 17 : "1.6rem",
                  }}
                >
                  Senior Computer Science Student, Web Developer{" "}
                </div>
              </div>
            </Typography>
          </Zoom>
          <Zoom in={true} style={{ transitionDelay: "2s" }}>
            <Box
              sx={
                isMobile
                  ? {
                      display: "flex",
                      flexDirection: "column",
                      marginTop: "3vh",
                    }
                  : {
                      mt: 2,
                      display: "flex",
                    }
              }
            >
              <Button
                sx={isMobile ? { mb: 2, height: "55px" } : { mr: 2 }}
                variant="contained"
                color="primary"
                onClick={() => scrollToSection("messaging")}
              >
                Message me !
              </Button>
              <Button
                variant="outlined"
                className={classes.myBtn}
                sx={isMobile ? { height: "55px" } : {}}
                color="secondary"
                onClick={() => scrollToSection("myArticles")}
              >
                <span className="wrapper" />
                <span className="text"> My Articles</span>
              </Button>

              <Button
                variant="outlined"
                className={classes.myBtn}
                sx={isMobile ? { height: "55px" } : {}}
                color="primary"
                startIcon={
                  theme.palette.mode === "dark" ? <LightMode /> : <DarkMode />
                }
                onClick={toggleThemeMode}
              >
                {theme.palette.mode == "dark"
                  ? "Try light mode "
                  : "Try Dark Mode"}
              </Button>
            </Box>
          </Zoom>
        </Box>
        {isMobile ? (
          ""
        ) : (
          <img src="programming.svg" className={classes.myImg} />
        )}
      </Container>
      {isMobile ? (
        <div className={"wave-container mobile" + " " + theme.palette.mode}>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
            <path
              fill="#2196f3"
              fillOpacity="1"
              d="M0,96L60,122.7C120,149,240,203,360,208C480,213,600,171,720,170.7C840,171,960,213,1080,197.3C1200,181,1320,107,1380,69.3L1440,32L1440,0L1380,0C1320,0,1200,0,1080,0C960,0,840,0,720,0C600,0,480,0,360,0C240,0,120,0,60,0L0,0Z"
            ></path>
          </svg>
        </div>
      ) : (
        <div className={"wave-container" + " " + theme.palette.mode}>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
            <path
              fill="#2196f3"
              fillOpacity="1"
              d="M0,160L60,133.3C120,107,240,53,360,42.7C480,32,600,64,720,69.3C840,75,960,53,1080,53.3C1200,53,1320,75,1380,85.3L1440,96L1440,0L1380,0C1320,0,1200,0,1080,0C960,0,840,0,720,0C600,0,480,0,360,0C240,0,120,0,60,0L0,0Z"
            ></path>
          </svg>
        </div>
      )}
      <Box className={classes.scrollDown + " " + classes.scrollRight}>
        <Typography variant="body1" component="span" color="text.primary">
          {" "}
          Scroll down !{" "}
        </Typography>
        <KeyboardArrowDown />
      </Box>
      <Box className={classes.scrollDown + " " + classes.scrollLeft}>
        <Typography variant="body1" component="span" color="text.primary">
          Scroll down !{" "}
        </Typography>
        <KeyboardArrowDown />
      </Box>
    </Box>
  );
}
