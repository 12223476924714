import * as React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { useMode } from '../contexts/ModeProvider';

function Copyright() {
  return (
    <Typography variant="body2" color="text.secondary" align="center">
      {'Copyright © '}
      
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

function Footer(props) {
  const { description, title } = props;
const {colors} = useMode();
  return (
    <Box component="footer" sx={{backgroundColor : colors.bg1.bg, color: colors.textMain , pt: 4, pb: 2 }}>
      <Container maxWidth="lg">
        <Typography variant="subtitle1" align="center" gutterBottom>
          {title} v1.0 - {description}
        </Typography>
        <Copyright />
      </Container>
    </Box>
  );
}

Footer.propTypes = {
  description: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default Footer;
