import React, { useState, useEffect } from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import { useTheme } from "@emotion/react";
import { makeStyles } from "@mui/styles";
import Slider from "@mui/material/Slider";
import Wave from "../../assets/waveg.png";

// import {startSaderRain} from '../../lib/SaderRain'
import { useMode } from "../../contexts/ModeProvider";
import { Fade } from "@mui/material";
// import {initOciliator} from '../../lib/Oscilator'
const useStyles = makeStyles({
  root: {
    backgroundColor: ({ colors }) => colors.bgSpecial.bg,
    padding: "65px 16px",
    position: "relative",
  },
  bgWave: {
    position: "absolute",
    height: ({ isMobile }) => (isMobile ? "19%" : "100%"),
    zIndex: 1,
    width: "100%",
    left: 0,
  },
  slider: {
    "& .MuiSlider-markLabel": {
      color: "white !important",
    },
  },
  container: {
    zIndex: 99,
    position: "relative",
  },
});

const marks = [
  {
    value: 0,
    label: "Nothing",
  },
  {
    value: 33,
    label: "Short",
  },
  {
    value: 66,
    label: "Medium",
  },
  {
    value: 100,
    label: "All of it",
  },
];

export default function WhoAmI() {
  const { colors, isMobile } = useMode();
  const theme = useTheme();
  const [mark, setMark] = React.useState(33);
  const [bottom, setBottom] = React.useState("-18%");
  const classes = useStyles({ colors, isMobile, bottom });

  const handleChange = (e) => {
    setMark(e.target.value);
  };

  useEffect(() => {
    if (mark == 0 || (mark > 0 && mark < 33)) {
      setBottom("-18%");
    }
    if (mark > 33 && mark < 66) {
      setBottom("-16%");
    }
    if (mark > 66) {
      setBottom("-14%");
    }
  }, [mark]);

  return (
    <Box className={classes.root}>
      <svg
        style={isMobile ? { bottom: bottom } : { bottom: "-83%" }}
        className={classes.bgWave}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1440 320"
      >
        <path
          fill="#132f4c"
          fillOpacity="1"
          d="M0,128L48,112C96,96,192,64,288,48C384,32,480,32,576,69.3C672,107,768,181,864,181.3C960,181,1056,107,1152,101.3C1248,96,1344,160,1392,192L1440,224L1440,0L1392,0C1344,0,1248,0,1152,0C1056,0,960,0,864,0C768,0,672,0,576,0C480,0,384,0,288,0C192,0,96,0,48,0L0,0Z"
        ></path>
      </svg>
      <Container className={classes.container}>
        <Typography
          variant="h2"
          align="left"
          gutterBottom
          sx={{
            fontSize: "0.875rem",
            lineHeight: "1.5",
            fontWeight: "700",
            color: "#3399ff",
          }}
        >
          About Me !
        </Typography>
        <Typography
          variant="h2"
          align="left"
          sx={{
            mt: 1,
            color: "white",
          }}
        >
          Who is
          <span style={{ color: "#3399ff" }}> TONY </span> ?
        </Typography>
        <Typography
          variant="subtitle1"
          sx={{
            mb: 5,
            mt: 1,
            color: "#aab4be",
          }}
        >
          Description about myself. By the way, you can call me Tony or Antoin.
        </Typography>
        <Slider
          aria-label="Length of bio"
          defaultValue={100}
          value={mark}
          onChange={handleChange}
          step={null}
          className={classes.slider}
          marks={marks}
          sx={{ mb: 8 }}
        />
        {mark == 0 ? (
          <Fade in={mark == 0} style={{ transitionDuration: "1s" }}>
            <Typography
              sx={{ fontStyle: "italic" }}
              variant="h5"
              color="primary"
              gutterBottom
            >
              {" "}
              Just call me TONY{" "}
            </Typography>
          </Fade>
        ) : (
          ""
        )}
        {mark > 0 ? (
          <Fade in={mark > 0} style={{ transitionDuration: "1s" }}>
            <Typography
              sx={{ fontStyle: "italic !important" }}
              variant="body1"
              color="white"
              gutterBottom
            >
              I am a Computer Science student who has a strong passion and
              interest in the new technology and always ready towards developing
              my technical knowledge and experience.
            </Typography>
          </Fade>
        ) : (
          ""
        )}
        {mark > 33 ? (
          <Fade in={mark > 33} style={{ transitionDuration: "1s" }}>
            <Typography
              sx={{ fontStyle: "italic" }}
              variant="body1"
              color="white"
              gutterBottom
            >
              As a start, I taught myself basic programming using C++. As I
              developed my skills, I started getting involved web development
              world where I participated in many projects in my hometown as a
              way to gain more experience alongside with my university studies.
              Later on, I’ve obtained over 4 years experience working in C#
              .net, MVC5, JavaScript, AngularJs, and MS SQL Server in addition to
              web-application frameworks such ad Bootstrap and Jquery.
            </Typography>
          </Fade>
        ) : (
          ""
        )}
        {mark > 66 ? (
          <Fade in={mark > 66} style={{ transitionDuration: "1s" }}>
            <Typography
              sx={{ fontStyle: "italic" }}
              variant="body1"
              color="white"
              gutterBottom
            >
              I gained a 2 years experience building Rest APIs using express
              Node.js and building web pages using React and React Material UI.
              I am currently working as a full-stack web developer with industry
              experience building web applications and ERP systems. I am a fast
              learner, self-motivated, easy to adapt and passionate to
              converting academic knowledge into web-applications.
            </Typography>
          </Fade>
        ) : (
          ""
        )}
      </Container>
      {/* <canvas id="canvas" > </canvas> */}
    </Box>
  );
}
