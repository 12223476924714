import React, {  useEffect } from "react";
import { useTheme, } from "@mui/styles";
import $ from "jquery";
import { initBlast } from "../lib/Blast";

export default function Animy({ content, alwaysColor = "#132F4C", animColor = "#ff6d00", css }) {
  const theme = useTheme();
  const [clas, setClass ] = React.useState();
  
  useEffect(() => {

    window.jquery = window.$ = $;
    initBlast($, window, document);
    

    const cc = content.replaceAll(" ", "").replaceAll(",","").replace("?", "").toLowerCase() + "_123";
    
    setClass(cc);
    const clsJq = "." + cc;
    var chars = $(clsJq).blast({ delimiter: "character" });



    function animate(ele) {
      $(ele)
        .addClass("saderAnimate")
        .addClass(cc)
        .addClass("rubberBand")
        .css("color", animColor);
      setTimeout(
        () =>
          $(ele)
            .removeClass("rubberBand saderAnimate")
            .css("color", alwaysColor),
        2000
      );
    }
    
    chars.each(function (i) {
      animate(this);
    });
    $("."+cc+" .blast").mouseover(function () {
      animate(this);
    });
  });

  return (
    <span style={{ ...css }} className={`${clas} sader_animate`}>
      {content}{" "}
    </span>
  );
}
