import React, {useState, useEffect} from "react";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  root: {},
})

export default function Projects() {
  const classes = useStyles();

  return (
    <Container className={classes.root}>
       <Typography variant="h5" align="left" gutterBottom> This page will be available soon. </Typography>
      
    </Container>
  );
}
