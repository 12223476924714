import React, { useState, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import "./newYear.css";
import $ from "jquery";
import { useMode } from "../contexts/ModeProvider";
import { Button } from "@mui/material";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles({
  root: {
    "& .ano_novo>span, .fogos>div": {
      color: ({ colors }) => colors.textMain,
    },
    "& .feliz, .feliz2": {
      color: ({ colors }) => colors.textPrimary,
    },
  },
});

export default function NewYear() {
  const { colors } = useMode();
  const history = useHistory();
  function handleClick() {
    history.push("/#message");
  }

  const classes = useStyles({ colors });
  React.useEffect(() => {
    const seteFirst = (cb) => $(".sete").animate({ right: "22%" }, 200, cb);
    const seteSecond = (cb) => $(".sete").animate({ right: "15%" }, 200, cb);
    const seteThird = (cb) => $(".sete").animate({ right: "27%" }, 200, cb);

    seteFirst(() => {
      seteSecond(() => {
        seteFirst(() => {
          seteSecond(() => {
            console.log("hi");
            $(".seis").addClass("shake");

            const myTimeout = setTimeout(() => {
              $(".seis").animate({ top: "-33%" }, 200, () => {
                seteThird(() => {
                  $(".feliz").addClass("happy");
                  $(".feliz2").addClass("fromTony");
                });
              });
            }, 1000);
          });
        });
      });
    });
  }, []);
  return (
    <div className={classes.root}>
      <div className="myContainer">
        <div className="feliz">Happy new year</div>
        <div className="ano_novo">
          <span>202</span>
          <span className="seis">1</span>
          <span className="sete">2</span>
        </div>
        <div className="feliz2">
            From Tony Sader
            <Button
              variant="contained"
              color="primary"
              onClick={handleClick}
            >
              Message me !
            </Button>
            </div>
      </div>
    </div>
  );
}
