import React, { useState, useEffect } from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import { makeStyles } from "@mui/styles";
import $ from "jquery";
const useStyles = makeStyles({
  root: {},
  dancing: {},
});

export default function PartyEgg() {
  const classes = useStyles();
  useEffect(() => {
    const imgs = [
        `<img id="dancing" style="top: 0; left: -30vw"   src="eggs/vector_dancing.gif" />`,
      `<img id="dancing" style="top: 0; left: 30vw"  src="eggs/vector_dancing.gif" />`,
      `<img id="dancing" style="top: 0; left:0"   src="eggs/vector_dancing.gif" />`,
      `<img id="dancing" style="top: 20vh; left: 0vw"  src="eggs/vector_dancing.gif" />`,
      `<img id="dancing" style="top: 40vh; left: 0vw"   src="eggs/vector_dancing.gif" />`,
      `<img id="dancing" style="top: 60vh; left: 0vw"  src="eggs/vector_dancing.gif" />`,
      `<img id="dancing" style="top: 80vh; left: 0"   src="eggs/vector_dancing.gif" />`,
    ];
    $("#overall").append(imgs[0]);
    let i = 1;
    const dncersInterval = window.setInterval(() => {
      $("#overall").append(imgs[i]);
      i++;
      if (i == imgs.length) window.clearInterval(dncersInterval);
    }, 1000);
  }, []);

  return <Box id="overall" className={classes.root}></Box>;
}
