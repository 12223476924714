import React, { useState, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import { ThemeProvider } from "@mui/material/styles";

import { useMode } from "../contexts/ModeProvider";
import Header from "./Header";
import Footer from "./Footer";

import { useLocation } from "react-router";
import { Box } from "@mui/system";
const useStyles = makeStyles((theme) => {
  console.log("them,e: ", theme);
  return {
    root: {
      // backgroundColor: grey[200]
    },
    page: {
      minHeight: "40vh",
      marginTop: ({ isMobile }) => (isMobile ? 10 : 36),
      paddingTop: ({ isMobile }) => (isMobile ? 10 : 30),
    },
  };
});

export default function Layout({ children, toggleThemeMode }) {
  const location = useLocation();
  const { theme, isMobile } = useMode();
  const classes = useStyles({ isMobile });

  return (
    <ThemeProvider theme={theme}>
      <Box
        className={classes.root}
        sx={{ backgroundColor: theme.palette.background.default }}
      >
        <div style={{ zIndex: 99, minHeight: "80vh" }}>
          <Header title="Antoin Sader" toggleThemeMode={toggleThemeMode} />

          <main className={classes.page}>{children}</main>
        </div>
        <Footer title="Antoin Sader" description="Powered by Antoin Sader" />
      </Box>
    </ThemeProvider>
  );
}
