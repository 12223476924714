const scrambles =  [
    {id: 1, scramble: `B R F U F2 R2 U' R2 B2 U B2 U L2 U2 R B R D2 L' F`},
    {id: 2, scramble: `U2 R U R2 F U2 L2 F L2 F L2 F L2 D2 L' D L2 R2 F U2 L'`},
    {id: 3,scramble: `D2 F' U' B D' R' U D B' R2 U2 L2 F2 U2 B' R2 F R2 B' D2 R`},
    {id: 4,scramble: `U' B2 L2 U' F' U R' U F' U2 B2 L2 B D2 R2 B' U2 R2 B L2`},
    {id: 5,scramble: `D' U2 R2 B2 D' U2 L2 B2 F2 U' B2 U' L' U R2 D L F2 U F U'`},
    {id: 6, scramble: `B U2 R2 B2 R2 U2 R2 U' L2 R2 U' B2 D R' F2 D L' B D F' U2`},
    {id: 7,scramble: `L U D2 L' F2 U2 L D2 L' D2 F2 R' D2 L F' D' B U L2 F2 U'`},
    {id: 8, scramble: `B' D' B R2 B F L2 R2 F' U2 F' R2 F D R D' R' D2 U2 R`},
    {id: 9, scramble: `F U' F2 L2 D2 B' R2 D2 L2 U2 F' R' D2 L' D R' U' L2`},
    {id: 10, scramble: `L2 U' D2 B L2 D2 B' D2 L2 B L2 F' D' R2 U2 R' U B2 F'`},
    {id: 11,scramble: `F' L' U2 B D R F U D2 R F2 R' D2 R2 B2 R' F2 D2 L2 B R`},
    {id: 12, scramble: `R D2 B2 U L2 U2 R2 B2 D' R2 F2 D' U' B' U' F' R D L' F U2`},
    {id: 13,scramble: `D L2 B2 R' B' D2 B' L D2 F2 L B2 R2 U2 L' F2 L B2 D2 U'`},
    {id: 14,scramble: `R D2 U2 R U2 F2 D2 R' F2 L R B L U' B R' D2 L B2 F2`},
    {id: 15, scramble: `R D2 U2 R U2 F2 D2 R' F2 L R B L U' B R' D2 L B2 F2`},
].map(ele => ({
    ...ele,
    ar: ele.scramble.split(" ")
}))


export default function getRandomScramble() {
    const rndmId = Math.floor(Math.random() * scrambles.length + 1);
    const ele = scrambles.find(ele => ele.id == rndmId);
    if(ele){
        return ele;
    }
    return null;
}
