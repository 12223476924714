import React, { useState, useEffect } from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import { makeStyles, useTheme } from "@mui/styles";
import {
  Button,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
} from "@mui/material";
import { useMode } from "../../contexts/ModeProvider";
import fakeApiImg from "../../assets/projects/fakeapi.png";
import weatherImg from "../../assets/projects/weather.png";
const useStyles = makeStyles((theme) => ({
  projectsGrid: {
    display: "flex",
    flexWrap: "wrap",
    padding: 0,
    margin: 0,
  },
  projectBox: {
    width: ({ isMobile }) => (isMobile ? "100%" : "45%"),
    height: "60vh",
    position: "relative",
    overflow: "hidden",
    marginTop: ({ isMobile }) => (isMobile ? 20 : 0),
    marginLeft: ({ isMobile }) => (isMobile ? 0 : 20),
    padding: 0,
    paddingBottom: ({ isMobile }) => (isMobile ? 0 : "2rem"),
  },
  media: {
    height: "60%",
    aspectRatio: "1/1",
    objectFit: "contain",
    objectPosition: "center",
  },
  projects: {
    marginTop: 20,
    paddingTop: ({ isMobile }) => (isMobile ? "2rem" : "10rem"),

    paddingBottom: 20,
    background: ({ colors }) => colors.bg2.bg,
    minHeight: "99vh",
    position: "relative",
  },
  action: {
    marginTop: "1rem"
  }
}));

export default function Projects() {
  const { colors, isMobile } = useMode();
  const classes = useStyles({ colors, isMobile });
  const theme = useTheme();

  return (
    <Box className={classes.projects}>
      <Container>
        <Typography
          variant="h2"
          color="primary"
          align="left"
          gutterBottom
          sx={{
            fontSize: "0.875rem",
            lineHeight: "1.5",
            fontWeight: "700",
          }}
        >
          Small Projects
        </Typography>
        <Typography
          variant="h2"
          color="text.primary"
          align="left"
          sx={{
            mt: 1,
          }}
        >
          Small
          <span style={{ color: theme.palette.primary.main }}>
            projects
          </span>{" "}
          are just projects I have created to grow my experience in such a field
          or a technology.
        </Typography>
        <Typography
          color="text.secondary"
          variant="subtitle1"
          sx={{
            mb: 5,
            mt: 1,
          }}
        >
          Each website takes about 3 days.
        </Typography>

        <Box className={classes.projectsGrid}>
          {projects.map((ele) => (
            <Card key={ele.id} className={classes.projectBox}>
              <CardMedia
                className={classes.media}
                component="img"
                alt={ele.name}
                image={ele.img}
              />
              <CardContent>
                <Typography
                  fontWeight={700}
                  variant="h5"
                  color="text.primary"
                  gutterBottom
                >
                  {ele.name}
                </Typography>
                <Typography variant="body1" color="text.primary" gutterBottom>
                  {" "}
                  {ele.description}{" "}
                </Typography>
                <Button variant="contained" sx={{mt: 2}} color="primary">
                  <a
                    style={{
                      textDecoration: "none",
                      color: "inherit",
                      fontSize: "inherit",
                    }}
                    target="_blank"
                    href={ele.goTo}
                  >
                    Visit Now !
                  </a>
                </Button>
              </CardContent>
            </Card>
          ))}
        </Box>
      </Container>
    </Box>
  );
}

const projects = [
  {
    id: 1,
    img: fakeApiImg,
    name: "Sader Fake API",
    description:
      "Fake restful API made with Express.js and Node.js as backend and react, Next.js as front end",
    goTo: "https://fakeapi.antoinsader.site/",
  },
  {
    id: 2,
    img: weatherImg,
    name: "Weather",
    description:
      "Simple weather tool to show you the weather in your address depending on your IP. It will show weather in current time and for the next 48 hours.",
    goTo: "/weather",
  },
];
