import React from "react";
import { makeStyles } from "@mui/styles";
import { Box } from "@mui/system";

import Projects from "../Components/Sections/Projects";
import Articles from "../Components/Sections/Articles";
import Intro from "../Components/Sections/Intro";
import Message from "../Components/Sections/Message";
import WhoAmI from "../Components/Sections/WhoAmI";
import Pad from "../Components/Sections/Pad";
import Eggs from "../Components/Sections/Eggs";
import { useMode } from "../contexts/ModeProvider";
import { scroller } from "react-scroll";

const useStyles = makeStyles((theme) => ({
 
}));

export default function Home() {
  const classes = useStyles();
  // const { isMobile } = useMode();
  React.useEffect(() => {
    
    const loc = window.location.href;
    const ind = loc.indexOf("#");
    if(ind > -1){
      const msg= loc.substring(ind + 1, loc.length);
      console.log("msg: " , msg);
      if(msg == "message"){
        scrollToSection("messaging")
      }
    }
  }, [])
  const scrollToSection = (cls) => {
    scroller.scrollTo(cls, {
      duration: 800,
      delay: 0,
      smooth: "easeInOutQuart",
    });
  };
  const isMobile = false;
  return (
    <Box className={classes.root + " rootAll"}>

      <Intro scrollToSection={scrollToSection} />
      <Pad /> 
      <WhoAmI />
      
      <Projects />
      
      <Eggs />

      <Articles />
      <Message />
    </Box>
  );
}
