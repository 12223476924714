import React, { useState, useEffect } from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import { makeStyles } from "@mui/styles";
import { useMode } from "../contexts/ModeProvider";
import html2canvas from "html2canvas";
const useStyles = makeStyles({
  root: {
    backgroundColor: ({ colors }) => colors.bg1.bg,
    minHeight: "76vh",
  },
  wrapper: {
    display: "flex",
    flexDirection: ({ isMobile }) => (isMobile ? "column" : "row"),

    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    height: "100%",
  },
  panelWrapper: {
    minHeight: ({ isMobile }) => (isMobile ? "42vh" : "56vh"),
    width: ({ isMobile }) => (isMobile ? "100%" : "50%"),
    background: "url('alpaca/backgrounds/darkblue70.png')",
    position: "relative",
  },
  optionsWrapper: {
    minHeight: ({ isMobile }) => (isMobile ? "42vh" : "65vh"),
    width: ({ isMobile }) => (isMobile ? "100%" : "50%"),
    marginLeft: ({ isMobile }) => (isMobile ? "0px" : "25px"),
  },
  accessories: {
    position: "absolute",
    width: ({ isMobile }) => (isMobile ? "100%" : "75%"),

    zIndex: 8,
  },
  ears: {
    width: ({ isMobile }) => (isMobile ? "100%" : "75%"),

    position: "absolute",
  },
  eyes: {
    zIndex: 15,
    width: ({ isMobile }) => (isMobile ? "100%" : "75%"),

    position: "absolute",
    top: "-25px",
  },
  hair: {
    width: ({ isMobile }) => (isMobile ? "100%" : "75%"),

    position: "absolute",
    zIndex: 7,
  },
  neck: {
    width: ({ isMobile }) => (isMobile ? "100%" : "75%"),

    position: "absolute",
  },
  leg: {
    width: ({ isMobile }) => (isMobile ? "100%" : "75%"),

    position: "absolute",
  },
  mouth: {
    width: ({ isMobile }) => (isMobile ? "100%" : "75%"),

    position: "absolute",
    zIndex: 9,
  },
  nose: {
    width: ({ isMobile }) => (isMobile ? "100%" : "75%"),

    position: "absolute",
    zIndex: 9,
  },
  option: {
    marginRight: "8px !important",
    marginBottom: "8px !important",
  },
});

export default function ImgGenerator() {
  const { colors, isMobile } = useMode();
  const classes = useStyles({ colors, isMobile });
  const [neck, setNeck2] = React.useState("alpaca/neck/default.png");
  const setNeck = (pth) => {
    let objects = objs;
    const opts = objects.find((ele) => ele.displayName == "Neck").options;
    opts.forEach((oo) => (oo.is_active = false));
    let name = pth
      .replace("alpaca/neck/", "")
      .replace(".png", "")
      .toLowerCase();
    name = name.indexOf("-") == -1 ? name : name.replace("-", " ");
    opts.find(
      (ele) => ele.displayName.toLowerCase() == name.toLowerCase()
    ).is_active = true;
    setNeck2(pth);
    setObjs(objects);
  };
  const [mouth, setMouth2] = React.useState("alpaca/mouth/default.png");
  const setMouth = (pth) => {
    let objects = objs;
    const opts = objects.find((ele) => ele.displayName == "Mouth").options;
    opts.forEach((oo) => (oo.is_active = false));
    let name = pth
      .replace("alpaca/mouth/", "")
      .replace(".png", "")
      .toLowerCase();
    name = name.indexOf("-") == -1 ? name : name.replace("-", " ");
    opts.find(
      (ele) => ele.displayName.toLowerCase() == name.toLowerCase()
    ).is_active = true;
    setMouth2(pth);
    setObjs(objects);
  };
  const [leg, setLeg2] = React.useState("alpaca/leg/default.png");
  const setLeg = (pth) => {
    let objects = objs;
    const opts = objects.find((ele) => ele.displayName == "Leg").options;
    opts.forEach((oo) => (oo.is_active = false));
    let name = pth.replace("alpaca/leg/", "").replace(".png", "").toLowerCase();
    name = name.indexOf("-") == -1 ? name : name.replace("-", " ");
    opts.find(
      (ele) => ele.displayName.toLowerCase() == name.toLowerCase()
    ).is_active = true;
    setLeg2(pth);
    setObjs(objects);
  };
  const [hair, setHair2] = React.useState("alpaca/hair/default.png");
  const setHair = (pth) => {
    let objects = objs;
    const opts = objects.find((ele) => ele.displayName == "Hair").options;
    opts.forEach((oo) => (oo.is_active = false));
    let name = pth
      .replace("alpaca/hair/", "")
      .replace(".png", "")
      .toLowerCase();
    name = name.indexOf("-") == -1 ? name : name.replace("-", " ");
    opts.find(
      (ele) => ele.displayName.toLowerCase() == name.toLowerCase()
    ).is_active = true;
    setHair2(pth);
    setObjs(objects);
  };
  const [eyes, setEyes2] = React.useState("alpaca/eyes/default.png");
  const setEyes = (pth) => {
    let objects = objs;
    const opts = objects.find((ele) => ele.displayName == "Eyes").options;
    opts.forEach((oo) => (oo.is_active = false));
    let name = pth
      .replace("alpaca/eyes/", "")
      .replace(".png", "")
      .toLowerCase();
    name = name.indexOf("-") == -1 ? name : name.replace("-", " ");
    opts.find(
      (ele) => ele.displayName.toLowerCase() == name.toLowerCase()
    ).is_active = true;
    setEyes2(pth);
    setObjs(objects);
  };
  const [ears, setEars2] = React.useState("alpaca/ears/default.png");
  const setEars = (pth) => {
    let objects = objs;
    const opts = objects.find((ele) => ele.displayName == "Ears").options;
    opts.forEach((oo) => (oo.is_active = false));
    let name = pth
      .replace("alpaca/ears/", "")
      .replace(".png", "")
      .toLowerCase();
    name = name.indexOf("-") == -1 ? name : name.replace("-", " ");
    opts.find(
      (ele) => ele.displayName.toLowerCase() == name.toLowerCase()
    ).is_active = true;
    setEars2(pth);
    setObjs(objects);
  };
  const [accessories, setAccessories2] = React.useState(
    "alpaca/accessories/headphone.png"
  );
  const setAccessories = (pth) => {
    let objects = objs;
    const opts = objects.find(
      (ele) => ele.displayName == "Accessories"
    ).options;
    opts.forEach((oo) => (oo.is_active = false));
    let name = pth
      .replace("alpaca/accessories/", "")
      .replace(".png", "")
      .toLowerCase();
    name = name.indexOf("-") == -1 ? name : name.replace("-", " ");
    opts.find(
      (ele) => ele.displayName.toLowerCase() == name.toLowerCase()
    ).is_active = true;
    setAccessories2(pth);
    setObjs(objects);
  };

  const [objs, setObjs] = useState([
    {
      id: 1,
      displayName: "Accessories",
      options: [
        {
          id: 4,
          displayName: "Headphone",
          onClick: () => setAccessories("alpaca/accessories/headphone.png"),
          is_active: true,
        },
        {
          id: 1,
          displayName: "Earings",
          onClick: () => {
            setAccessories("alpaca/accessories/earings.png");
          },
          is_active: false,
        },
        {
          id: 2,
          displayName: "Flower",
          onClick: () => setAccessories("alpaca/accessories/flower.png"),
          is_active: false,
        },
        {
          id: 3,
          displayName: "Glasses",
          onClick: () => setAccessories("alpaca/accessories/glasses.png"),
          is_active: false,
        },
      ],
    },
    {
      id: 2,
      displayName: "Ears",
      options: [
        {
          id: 1,
          displayName: "Default",
          onClick: () => setEars("alpaca/ears/default.png"),
          is_active: true,
        },
        {
          id: 2,
          displayName: "Tilt backward",
          onClick: () => setEars("alpaca/ears/tilt-backward.png"),
          is_active: false,
        },
        {
          id: 3,
          displayName: "Tilt forward",
          onClick: () => setEars("alpaca/ears/tilt-forward.png"),
          is_active: false,
        },
      ],
    },
    {
      id: 3,
      displayName: "Eyes",
      options: [
        {
          id: 1,
          displayName: "Default",
          onClick: () => setEyes("alpaca/eyes/default.png"),
          is_active: true,
        },
        {
          id: 2,
          displayName: "Angry",
          onClick: () => setEyes("alpaca/eyes/angry.png"),
          is_active: false,
        },
        {
          id: 3,
          displayName: "Naughty",
          onClick: () => setEyes("alpaca/eyes/naughty.png"),
          is_active: false,
        },
        {
          id: 4,
          displayName: "Panda",
          onClick: () => setEyes("alpaca/eyes/panda.png"),
          is_active: false,
        },
        {
          id: 5,
          displayName: "Smart",
          onClick: () => setEyes("alpaca/eyes/smart.png"),
          is_active: false,
        },
        {
          id: 6,
          displayName: "Star",
          onClick: () => setEyes("alpaca/eyes/star.png"),
          is_active: false,
        },
      ],
    },
    {
      id: 4,
      displayName: "Hair",
      options: [
        {
          id: 1,
          displayName: "Default",
          onClick: () => setHair("alpaca/hair/default.png"),
          is_active: true,
        },
        {
          id: 2,
          displayName: "Bang",
          onClick: () => setHair("alpaca/hair/bang.png"),
          is_active: false,
        },
        {
          id: 3,
          displayName: "Curls",
          onClick: () => setHair("alpaca/hair/curls.png"),
          is_active: false,
        },
        {
          id: 4,
          displayName: "Elegant",
          onClick: () => setHair("alpaca/hair/elegant.png"),
          is_active: false,
        },
        {
          id: 5,
          displayName: "Fancy",
          onClick: () => setHair("alpaca/hair/fancy.png"),
          is_active: false,
        },
        {
          id: 6,
          displayName: "Quiff",
          onClick: () => setHair("alpaca/hair/quiff.png"),
          is_active: false,
        },
        {
          id: 7,
          displayName: "Short",
          onClick: () => setHair("alpaca/hair/short.png"),
          is_active: false,
        },
      ],
    },

    {
      id: 5,
      displayName: "Leg",
      options: [
        {
          id: 1,
          displayName: "Default",
          onClick: () => setLeg("alpaca/leg/default.png"),
          is_active: true,
        },
        {
          id: 2,
          displayName: "Bubble tea",
          onClick: () => setLeg("alpaca/leg/bubble-tea.png"),
          is_active: false,
        },
        {
          id: 3,
          displayName: "Cookie",
          onClick: () => setLeg("alpaca/leg/cookie.png"),
          is_active: false,
        },
        {
          id: 4,
          displayName: "Game console",
          onClick: () => setLeg("alpaca/leg/game-console.png"),
          is_active: false,
        },
        {
          id: 5,
          displayName: "Tilt backward",
          onClick: () => setLeg("alpaca/leg/tilt-backward.png"),
          is_active: false,
        },
      ],
    },
    {
      id: 6,
      displayName: "Mouth",
      options: [
        {
          id: 1,
          displayName: "Default",
          onClick: () => setMouth("alpaca/mouth/default.png"),
          is_active: true,
        },
        {
          id: 2,
          displayName: "Astonished",
          onClick: () => setMouth("alpaca/mouth/astonished.png"),
          is_active: false,
        },
        {
          id: 3,
          displayName: "Eating",
          onClick: () => setMouth("alpaca/mouth/eating.png"),
          is_active: false,
        },
        {
          id: 4,
          displayName: "Laugh",
          onClick: () => setMouth("alpaca/mouth/laugh.png"),
          is_active: false,
        },
        {
          id: 5,
          displayName: "Tongue",
          onClick: () => setMouth("alpaca/mouth/tongue.png"),
          is_active: false,
        },
      ],
    },
    {
      id: 7,
      displayName: "Neck",
      options: [
        {
          id: 1,
          displayName: "Default",
          onClick: () => setNeck("alpaca/neck/default.png"),
          is_active: true,
        },
        {
          id: 2,
          displayName: "Bend backward",
          onClick: () => setNeck("alpaca/neck/bend-backward.png"),
          is_active: false,
        },
        {
          id: 3,
          displayName: "Bend forward",
          onClick: () => setNeck("alpaca/neck/bend-forward.png"),
          is_active: false,
        },
        {
          id: 4,
          displayName: "Thick",
          onClick: () => setNeck("alpaca/neck/thick.png"),
          is_active: false,
        },
      ],
    },
  ]);

  const random = () => {
    const objects = objs;
    objects.forEach(ele => {
      ele.options.forEach(opt => opt.is_active = false);
      const rndm =  Math.floor(Math.random() * ele.options.length);
      if(ele.options[rndm]){

        ele.options[rndm].onClick();
        ele.options[rndm].is_active = true;
      } 
    })
    setObjs(objects);
  };
  const saveImg = () => {
    html2canvas(document.querySelector("#myImg")).then((canvas) => {
      var link = document.createElement("a");
      link.download = "filename.png";
      link.href = canvas.toDataURL();
      link.click();
    });
  };
  const Btns = () => (
    <Box>
      <Typography variant="h4" color="text.primary" gutterBottom>
        Actions:{" "}
      </Typography>
      <Box display="flex">
        <Button variant="contained" color="secondary" style={{marginRight: 10 }} onClick={saveImg}> Download </Button>
        <Button variant="contained" color="secondary" onClick={random}> Random </Button>
      </Box>
    </Box>
  );
  return (
    <Box className={classes.root}>
      <Container>
        <Typography
          style={isMobile ? { marginTop: "45px" } : {}}
          variant="h4"
          color="text.primary"
          gutterBottom
        >
          Alpaca Generator{" "}
        </Typography>

        <Box className={classes.wrapper}>
          <Box className={classes.panelWrapper} id="myImg">
            <img className={classes.accessories} src={accessories} />
            <img className={classes.ears} src={ears} />
            <img className={classes.eyes} src={eyes} />
            <img className={classes.nose} src="alpaca/nose.png" />
            <img className={classes.hair} src={hair} />
            <img className={classes.leg} src={leg} />
            <img className={classes.mouth} src={mouth} />
            <img className={classes.neck} src={neck} />
          </Box>
          <Box
            className={classes.optionsWrapper}
            style={
              isMobile
                ? {
                    height: "58vh",
                    marginTop: 8,
                    overflowY: "scroll",
                  }
                : {}
            }
          >
            {objs &&
              objs.map((ele) => (
                <Box key={ele.id}>
                  <Typography variant="h5" color="text.primary" gutterBottom>
                    {ele.displayName}{" "}
                  </Typography>
                  <Box display="flex" flexWrap="wrap">
                    {ele.options.map((opt) => (
                      <Button
                        key={opt.id}
                        className={classes.option}
                        variant={opt.is_active ? "contained" : "outlined"}
                        color="primary"
                        onClick={opt.onClick}
                      >
                        {opt.displayName}
                      </Button>
                    ))}
                  </Box>
                </Box>
              ))}
            <Btns />
          </Box>
        </Box>
      </Container>
    </Box>
  );
}
