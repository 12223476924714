import React, { useState, useEffect } from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import { makeStyles } from "@mui/styles";
import $ from "jquery";
const useStyles = makeStyles({
  root: {
    position: "fixed",
    zIndex: 999999999999,
    width: "100%",
    height: "100%",
    top: 0,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: "#001e3cde",
  },
});

export default function FlipCoin() {
  const classes = useStyles();
  useEffect(() => {
    const coin = document.getElementById("coin");
    coin.style.animation = "none";
    if (Math.random() <= 0.5) {
      coin.style.animation = "flip-heads 4s forwards";
    } else {
      coin.style.animation = "flip-tails 4s forwards";
    }
  }, []);
  return (
    <Box className={classes.root}>
      <div id="coin">
        <div className="heads">
          <img src="./eggs/coin1.png" alt="Image for coin's head" />
        </div>
        <div className="tails">
          <img src="./eggs/coin2.png" alt="Image for coin's tail" />
        </div>
      </div>
    </Box>
  );
}
