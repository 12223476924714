import BadUsb from "./BadUsb";
import DeepFake from './DeepFake'
import React, { useState, useEffect } from "react";

const Custom = ({ _id }) =>
  _id == "012345678901234567891234" ? <BadUsb /> : _id == "012345678901234567891236" ?
  <DeepFake />
  : "";

export default Custom;
