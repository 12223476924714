import React, { useState, useEffect } from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import { makeStyles } from "@mui/styles";

import video from "../../../assets/badusb.mp4";

import { useMode } from "../../../contexts/ModeProvider";
import Video from "../../../Components/Video";

const useStyles = makeStyles({
  root: {},
});

export default function BadUsb() {
  const { isMobile } = useMode();
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Container sx={isMobile ? {} : { display: "flex" }}>
        <Video src={video} autoPlay />

        <Box sx={isMobile ? { my: 3 } : { mx: 3 }}>
          <Typography variant="body1" color="text.primary" gutterBottom>
            Trust me, don't plug in any USB drive that you don't trust!
          </Typography>
          <Typography variant="body1" color="text.primary" gutterBottom>
            It might be a bad USB like the one in the video. It could steal your
            passwords, your credit card info, and it can get more dangerous!
          </Typography>
          <Typography
            variant="h5"
            color="primary"
            fontWeight={700}
            gutterBottom
          >
            What I've done here ?
          </Typography>
          <Typography variant="body1" color="text.primary" gutterBottom>
            I have used what is called WHID injector. It's just a usb like any
            normal USB we know, but when you plug it into the computer, it will
            recognize it as a keyboard that's why it's HID{" "}
            {"(Human Interface Device)"} and the W in the WHID is for wireless.
          </Typography>
          <Typography
            variant="h5"
            color="primary"
            fontWeight={700}
            gutterBottom
          >
            Why it is a keyboard ?
          </Typography>

          <Typography variant="body1" color="text.primary" gutterBottom>
            Because it is actually a keyboard that can type so fast and do
            things on the laptop as it's someone pressing on keyboard. Also, the
            laptop will not scan it for viruses.
          </Typography>
          <Typography
            variant="h5"
            color="primary"
            fontWeight={700}
            gutterBottom
          >
            Yeah ?
          </Typography>
          <Typography variant="body1" color="text.primary" gutterBottom>
            The WHID will host a wireless access point that can be accessed by
            any device through wireless.
          </Typography>
          <Typography
            variant="h5"
            color="primary"
            fontWeight={700}
            gutterBottom
          >
            Ok, what I've done when I connect to the access point ?
          </Typography>

          <Typography variant="body1" color="text.primary" gutterBottom>
            I can load any payload I want from mobile, I mean when I press
            "Start Payload" from my mobile I run a script on the laptop without
            touching any keys on the laptop
          </Typography>
          <Typography
            variant="h5"
            color="primary"
            fontWeight={700}
            gutterBottom
          >
            And why is it dangerous ?
          </Typography>
          <Typography variant="body1" color="text.primary" gutterBottom>
            Because I ran just a simple script to download image from the
            internet and set it as background, but the script can be more
            dangerous, for example it could steal all of your passwords and
            credit card's credentials
          </Typography>
        </Box>
      </Container>
    </Box>
  );
}
