const speed1 = 20;
const createSpeed = 40;
const squareSpeed = 5;

const cols = 15;
const rows = 5;
const noSquares = [
  3, 8, 13, 7, 6, 5, 23, 17, 16, 20, 26, 27, 35, 36, 37, 38, 43, 48, 47, 46, 45,
  58, 57, 62, 67, 68, 61, 60,
];

let WIDTH;
let HEIGHT;
let CVSHEIGHT;
let CVSWIDTH;
let SQRWIDTH;
let SQRHEIGHT;

let _ctx;
let _color = "dodgerblue";

let generateBall = false;
var squares = [];
let lastId = -1;

var myGameArea = {
  canvas: document.createElement("canvas"),

  start: function ($, container) {
    $(container + " canvas").remove();

    this.canvas.width = CVSWIDTH;
    this.canvas.height = CVSHEIGHT;
    this.context = this.canvas.getContext("2d");
    _ctx = this.context;
    $(container).append(this.canvas);
    this.frameNo = 0;
    this.currentHeight = CVSHEIGHT;


  },
  clear: function () {
    this.context.clearRect(0, 0, this.canvas.width, this.canvas.height);
  },
  stop: function () {
    clearInterval(this.interval);
  },
  continue: function () {
    this.interval = setInterval(updateGameArea, speed1);
  },

  currentColumn: 0,
  currentHeight: 100,
};
const getStopBottomBound = (id) => {
  return CVSHEIGHT - (id % rows) * SQRHEIGHT;
};

function component(width, height, x, y) {
  this.id = lastId + 1;

  this.bottomBound = getStopBottomBound(this.id);

  this.width = width;
  this.height = height;
  this.x = x + SQRWIDTH * parseInt(this.id / rows);
  this.y = y;

  this.update = function () {
    const ctx = _ctx;
    if (!noSquares.includes(this.id)) {
      this.image = new Image();
      this.image.src = "game/wizard.svg";

      ctx.drawImage(this.image, this.x, this.y - this.height/3, this.width/3 , this.height/3 );

      ctx.fillStyle = _color;
      ctx.fillRect(this.x, this.y, this.width, this.height);
    } else {
      ctx.fillStyle = "transparent";
      ctx.fillRect(this.x, this.y, this.width, this.height);
    }
  };
}

function updateGameArea() {
  myGameArea.clear();
  myGameArea.frameNo += 1;

  for (let i = 0; i < squares.length; i += 1) {
    if (
      !squares[i].stopped &&
      squares[i].y + squares[i].height <= squares[i].bottomBound
    ) {
      squares[i].y += squareSpeed;
    } else {
      squares[i].stopped = true;
    }

    squares[i].update();
  }

  let allstoped = true;
  for (let i = 0; i < squares.length; i += 1) {
    if (!squares[i].stopped) {
      allstoped = false;
      break;
    }
  }
  if (allstoped) {
    myGameArea.stop();
  }
}

export function startSaderRain($, color, isMobile, container) {
  const ratio = isMobile ? 15 : 60;
  WIDTH = cols * ratio;
  HEIGHT = rows * ratio;
  CVSHEIGHT = HEIGHT;
  CVSWIDTH = WIDTH;
  SQRWIDTH = ratio;
  SQRHEIGHT = ratio;

  _color = color;
  myGameArea.start($, container);
}

export function nextBall() {
  if (lastId < 76) {
    
    myGameArea.continue();
    squares.push(new component(SQRWIDTH, SQRHEIGHT, 0, 0));
    lastId++;
    while (noSquares.includes(lastId + 1)) {
      squares.push(new component(SQRWIDTH, SQRHEIGHT, 0, 0));
      lastId++;
    }
    updateGameArea();
  } else {
    return true;
  }
}
