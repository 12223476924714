import React, { useState, useEffect } from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import { makeStyles } from "@mui/styles";
import { exchanges as xcha } from "../Data/Euro";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { useMode } from "../contexts/ModeProvider";

const useStyles = makeStyles({
  root: {
    marginTop: 50,
  },
});

export default function RateExchange() {
    const {isMobile} = useMode();
  const classes = useStyles();
  const [rates, setRates] = React.useState();

  const [symbols, setSymbols] = React.useState();
  const [value, setValue] = React.useState();
  const [answer, setAnswer] = React.useState("");
  const [rate, setRate] = React.useState(0);

  const [eur, setEur] = React.useState(1);
  const handleChangeEur = (event) => {
    setEur(event.target.value);
    setUsr(event.target.value * rate);
  };
  const [usr, setUsr] = React.useState(1);
  const handleChangeUsr = (event) => {
    setUsr(event.target.value);
    setEur(event.target.value / rate);
  };

  useEffect(() => {
    const smbsKeys = Object.keys(xcha.symbols);
    const ratesKeys = Object.keys(xcha.rates);
    // console.log("smbs: " , smbs);
    let smbs = [];
    let rts = [];
    smbsKeys.forEach((key) => {
      smbs.push({
        key,
        label: xcha.symbols[key],
      });
    });
    ratesKeys.forEach((key) => {
      rts.push({
        key,
        value: xcha.rates[key],
      });
    });

    console.log("rates: ", rts);
    setRates(rts);
    setSymbols(smbs);
    setValue(smbs[0]);
  }, []);

  useEffect(() => {
    if (rates && value && rates.find((ele) => ele.key == value.key)) {
      console.log("value: ", value);
      const rt = rates.find((ele) => ele.key == value.key);
      setRate(rt.value)
      setAnswer(rt);
    }
  }, [value]);

  return (
    <Box className={classes.root}>
      <Container>
        <Box flexDirection={isMobile ? "column": "row"} display="flex" justifyContent={"space-between"} >
            <Typography variant="h4" color="text.primary">
            Euro exchanges{" "}
            </Typography>
            <Typography variant="subtitle2" color="text.primary"> Last updated date: 2022-Jan-01 </Typography>
            

        </Box>
        <Typography variant="caption" color="text.primary" > This is a demo, that's why it's only based on EUR currency </Typography>

        <Typography sx={{mt: 2}} variant="body1" color="text.primary" gutterBottom>
          Convert from Euro to:{" "}
        </Typography>
        {symbols ? (
          <Autocomplete
            value={value}
            onChange={(event, newValue) => {
              setValue(newValue);
            }}
            disablePortal
            options={symbols}
            getOptionLabel={(option) => option.label}
            sx={{ width: 300 }}
            renderInput={(params) => <TextField {...params} />}
          />
        ) : (
          ""
        )}
        {answer != "" ? (
          <>
            <Typography
              sx={{ mt: 2 }}
              variant="h5"
              color="primary"
              gutterBottom
            >
              Each 1 EUR equals {answer.value} {answer.key}
            </Typography>
            <Box flexDirection={isMobile ? "column": "row"} display="flex" sx={{ mt: 4 }} alignItems="center">
              <Typography sx={{mr: 2}} variant="body1" color="text.primary" gutterBottom>
                Each
              </Typography>
              <TextField label="Euro" sx={{mr: 2}} value={eur} onChange={handleChangeEur} />
              <Typography sx={{mr: 2}} variant="body1" color="primary" gutterBottom>
                EUR 
              </Typography>
              <Typography sx={{mr: 2}} variant="body1" color="text.primary" gutterBottom>
                =
              </Typography>
              <TextField
              sx={{mr: 2}}
                label={answer.key}
                value={usr}
                onChange={handleChangeUsr}
              />

              <Typography sx={{mr: 2}} variant="body1" color="primary" gutterBottom>
                {answer.key}
              </Typography>
            </Box>
          </>
        ) : (
          ""
        )}
      </Container>
    </Box>
  );
}
