import React, { useState, useEffect } from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import { makeStyles } from "@mui/styles";
import { CircularProgress, IconButton } from "@mui/material";
import { useTheme } from "@emotion/react";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { VectorMap } from "react-jvectormap";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import axios from "axios";

import Animy from "../Components/Animy";
import SaderBtn from "../Components/SaderBtn";

const useStyles = makeStyles({
  root: {
    backgroundColor: "white",
    minHeight: "90vh",
    paddingTop: 20,
  },
  container: {},
});

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function Ip({ info }) {
  const classes = useStyles();
  const theme = useTheme();
  const [data, setData] = React.useState(info);
  const [mapData, setMapData] = React.useState();
  const [newIp, setNewIp] = React.useState("");

  const [coppied, setCoppied] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [disable, setDisable] = React.useState(false);

  const handleClickDialogOpen = () => {
    setOpen(true);
  };

  const handleDialogClose = () => {
    setOpen(false);
  };

  const handleClick = () => {
    setCoppied(true);
  };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setCoppied(false);
  };

  useEffect(() => {
    let obj = {};
    obj[data.country_code] = 100000;

    setMapData(obj);
  }, []);
  const lookIp = async () => {
    setDisable(true);
    try {
      const result = await axios.get(
        `https://geolocation-db.com/json/${newIp}`
      );
      setData(result.data);
      console.log("data: ", result.data);
      let obj = {};
      obj[result.data.country_code] = 100000;

      setMapData(obj);
      handleDialogClose();
      setDisable(false);
    } catch (ex) {
      console.log("error: ", ex);
      setDisable(false);
    }
  };

  return (
    <Box className={classes.root}>
      <Container
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Box sx={{ width: "50%" }}>
          <Typography variant="h1" align="left" gutterBottom>
            <Animy
              css={{ fontWeight: 900 }}
              alwaysColor={theme.palette.primary.main}
              animColor={theme.palette.secondary.main}
              content="Your IP ?"
            />
          </Typography>
          {data ? (
            <>
              <Typography variant="h3" align="left" gutterBottom>
                You are in{" "}
                <Animy
                  css={{ fontWeight: 700 }}
                  alwaysColor={theme.palette.secondary.main}
                  animColor={theme.palette.primary.main}
                  content={data.country_name}
                />
              </Typography>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Typography variant="h6" align="left">
                  Your IP is:
                  <span style={{ color: theme.palette.primary.main }}>
                    {data.IPv4}
                  </span>
                </Typography>
                <IconButton
                  aria-label="copy"
                  onClick={() => {
                    navigator.clipboard.writeText(data.IPv4);
                    handleClick();
                  }}
                >
                  <ContentCopyIcon color="primary" />
                </IconButton>
              </Box>
            </>
          ) : (
            <CircularProgress />
          )}
          <Box sx={{ mt: 2, display: "flex" }}>
            <Button
              sx={{ mr: 2 }}
              variant="contained"
              onClick={handleClickDialogOpen}
              color="primary"
            >
              See other IP
            </Button>
            <SaderBtn component="a" href="/weather" text="Try Weather" />
          </Box>
        </Box>
        <Box sx={{ width: "40%" }}>
          {data ? (
            <VectorMap
              map={"world_mill"}
              backgroundColor={"#f3f6f9"} //change it to ocean blue: #0077be
              containerStyle={{
                width: "100%",
                height: "520px",
              }}
              zoomMax={20}
              //    onRegionClick={handleClick} //gets the country code
              containerClassName="map"
              regionStyle={{
                initial: {
                  fill: theme.palette.primary.main,
                  "fill-opacity": 0.9,
                  stroke: "none",
                  "stroke-width": 0,
                  "stroke-opacity": 0,
                },
                hover: {
                  "fill-opacity": 0.8,
                  cursor: "pointer",
                },
                selected: {
                  fill: theme.palette.secondary.dark, //color for the clicked country
                },
                selectedHover: {},
              }}
              regionsSelectable={true}
              series={{
                regions: [
                  {
                    values: mapData, //this is your data
                    scale: [theme.palette.secondary.main], //your color game's here
                    normalizeFunction: "polynomial",
                  },
                ],
              }}
            />
          ) : (
            ""
          )}
        </Box>
      </Container>

      <Snackbar open={coppied} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success" sx={{ width: "100%" }}>
          Your IP is coppied to clipboard
        </Alert>
      </Snackbar>
      <Dialog open={open} onClose={handleDialogClose}>
        <DialogTitle>IP Lookup</DialogTitle>
        <DialogContent>
          <DialogContentText>
            See the location of specific IP.
          </DialogContentText>
          <TextField
            autoFocus
            value={newIp}
            onChange={(e) => setNewIp(e.target.value)}
            margin="dense"
            label="IPV4"
            type="text"
            fullWidth
            variant="standard"
          />
        </DialogContent>
        <DialogActions>
          {disable ? (
            <CircularProgress />
          ) : (
            <Button onClick={lookIp} color="primary">
              Try
            </Button>
          )}

          <Button onClick={handleDialogClose}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
