import * as React from 'react';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { grey } from '@mui/material/colors';
function FeaturedPost({post}) {
  const { title, snippet, body, imgStr } = post;
 

  return (
    <Grid item xs={12} md={6}>
      <CardActionArea  component="a" href={`/one/?id=${post._id}`}>
        <Card sx={{ display: 'flex', flexDirection:{xs:"column-reverse", sm: "row"} }}>
          <CardContent sx={{ flex: 1 }}>
            <Typography component="h2"  variant="h5">
              {title}
            </Typography>
            {/* <Typography variant="subtitle1" color="text.secondary">
              {post.date}
            </Typography> */}
            <Typography variant="subtitle1" style={{direction: "rtl"}} paragraph>
              {snippet}
            </Typography>
            <Typography variant="subtitle1" style={{direction: "rtl"}} color="primary">
            اقرأ المزيد
            </Typography>
          </CardContent>
          <CardMedia
            component="img"
            sx={{ width:{xs:"100%", sm: 160} , }}
            image={imgStr}
            alt={title}
          />
        </Card>
      </CardActionArea>
    </Grid>
  );
}
FeaturedPost.propTypes = {
  post: PropTypes.shape({
    title: PropTypes.string.isRequired,
    snippet: PropTypes.string.isRequired,
    imgStr: PropTypes.string.isRequired,
    body: PropTypes.string.isRequired,
  }).isRequired,
};

export default FeaturedPost;
